import React from "react";
import styles from "./SignupStyles.module.css";

export default function CreditCardAuth() {
    return (
        <div className={styles.container}>
            <div className={styles.formTitle}>Payment Information</div>
            <p className={styles.formDescription}>
                Sessions are one-on-one with a tutor. Your card will only be
                charged after each session is completed.
                <br /> <br />
                If you need to cancel an appointment, please do so 24 hours
                before your scheduled appointment, or a 33% cancellation fee
                will be incurred.
                <br /> <br />
                The charge will appear as &quot;Alia Education&quot; on your
                credit card statement (It may appear differently if you are
                using American Express).
            </p>

            <div className={styles.authBox}>
                <h3>Credit Card Authorization</h3>
                <p>
                    Your card will be charged $1 as a one-time authorization
                    fee.
                </p>
                <div className={styles.amount}>$1.00</div>
            </div>

            <form className={styles.form}>
                {/* Form content will go here when adding payment fields */}
            </form>
        </div>
    );
}
