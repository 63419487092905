import React, { useEffect } from "react";
import { useStytchSession, useStytch } from "@stytch/react";
import { Authenticate } from "./Stytch";
import AuthSuccess from "./AuthSuccess";

// This component wraps protected routes and redirects to authentication if no session exists
const AuthWrapper = ({ children }) => {
    const { session } = useStytchSession();
    const stytchClient = useStytch();
    const params = new URLSearchParams(location.search);

    // Important: this useEffect is needed to ensure session is authenticated
    // which is required for cross-subdomain session sharing
    useEffect(() => {
        if (!session) return;
        stytchClient.session.authenticate({
            session_duration_minutes: 24 * 60 * 3, // 3 days
        });
    }, [session, stytchClient]);

    // const token = params.get("token");
    const tokenType = params.get("stytch_token_type");

    // Show authentication if no session or if session is expired or if we're on reset password page
    if (!session || tokenType === "reset_password") {
        localStorage.setItem("preAuthPath", window.location.pathname);
        return <Authenticate />;
    }

    if (session && window.location.pathname.includes("authenticate")) {
        return <AuthSuccess />;
    }

    // If authenticated, render the children
    return children;
};

export default AuthWrapper;
