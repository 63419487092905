import React, { useState } from "react";
import StudentInfo from "./StudentInfo";
import CreditCardAuth from "./CreditCardAuthInfo";
import StripePayment from "./StripeAuthorization";
import NavigationControls from "./NavigationControls";
import styles from "./SignupStyles.module.css";

export default function SignupFlow() {
    const [step, setStep] = useState(1);
    const [studentData, setStudentData] = useState({
        firstname: "",
        lastname: "",
        email: "",
    });

    const isValidEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const canProceed = () => {
        if (step === 1) {
            return (
                studentData.firstname.trim() !== "" &&
                studentData.lastname.trim() !== "" &&
                studentData.email.trim() !== "" &&
                isValidEmail(studentData.email)
            );
        }
        return true; // For other steps
    };

    const nextStep = () => {
        if (canProceed()) {
            setStep(step + 1);
        }
    };

    const previousStep = () => setStep(step - 1);

    return (
        <div className={styles.wrapper}>
            <header className={styles.header}>
                <img src="/assets/alia_logo_white.svg" alt="Company Logo" />
            </header>
            <div className={styles.signupForm}>
                {step === 1 && (
                    <StudentInfo
                        studentData={studentData}
                        setStudentData={setStudentData}
                    />
                )}

                {step === 2 && <CreditCardAuth />}

                {step === 3 && <StripePayment studentData={studentData} />}

                <NavigationControls
                    currentStep={step}
                    totalSteps={3}
                    onPrevious={previousStep}
                    onNext={nextStep}
                    canProceed={canProceed()}
                />
            </div>
        </div>
    );
}
