import React from "react";
import "./css/TermsAndConditions.css";
import TermsAndConditionsText from "../shared/utils/TermsAndConditionsText";

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <div className="terms-content">
                <div className="terms-header">
                    <img
                        src="/assets/alia_logo_blue.svg"
                        alt="Alia Logo"
                        className="terms-logo"
                    />
                </div>
                <h2>Terms and Conditions</h2>
                <TermsAndConditionsText />
            </div>
        </div>
    );
};

export default TermsAndConditions;
