import React from "react";
import ManageAbstract from "./ManageAbstract";
import { organizationRenderer, readOnlyRenderer } from "./CustomRenderers";

const columnConfig = {
    id: {
        width: 50,
        label: "ID",
        field: "id",
    },
    first_name: {
        width: 150,
        label: "First Name",
        field: "first_name",
    },
    last_name: {
        width: 150,
        label: "Last Name",
        field: "last_name",
    },
    email: {
        width: 200,
        label: "Email",
        type: "email",
    },
    organization: {
        width: 150,
        label: "Organization",
        field: "organization",
    },
    calendar_connected: {
        width: 150,
        label: "Google Calendar Connected",
        field: "calendar_connected",
    },
    zoomUserId: {
        width: 150,
        label: "Zoom ID",
        field: "zoom_user_id",
    },
    stripeId: {
        width: 150,
        label: "Stripe ID",
        field: "stripe_id",
    },
};

const newRowTemplate = {
    first_name: "",
    last_name: "",
    email: "",
    uuid: crypto.randomUUID(),
    phone_number: "",
    base_rate: "",
    subjects: [],
    organization: "",
    zoom_user_id: "",
    stripe_user_id: "",
    calendar_connected: false,
};

const customRenderers = {
    organization: organizationRenderer,
    id: readOnlyRenderer(),
    calendar_connected: readOnlyRenderer(),
};

function ManageTutors() {
    return (
        <ManageAbstract
            recordType="tutor"
            title="Tutors"
            columnConfig={columnConfig}
            newRowTemplate={newRowTemplate}
            customRenderers={customRenderers}
            primaryEmailField="email"
            identifierField="uuid"
        />
    );
}

export default ManageTutors;
