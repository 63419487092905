import React from "react";
import ManageAbstract from "./ManageAbstract";
import { uuidRenderer, readOnlyRenderer } from "./CustomRenderers";

const columnConfig = {
    id: {
        width: 150,
        label: "ID",
        field: "id",
    },
    // uuid: {
    //     width: 150,
    //     label: "UUID",
    //     field: "uuid",
    // },
    // session_uuid: {
    //     width: 150,
    //     label: "Session UUID",
    //     field: "session_uuid",
    // },
    // stripe_id: {
    //     width: 150,
    //     label: "Stripe ID",
    //     field: "stripe_id",
    // },
    client_email: {
        width: 150,
        label: "Client Email",
        field: "client_email",
    },
    amount: {
        width: 150,
        label: "Amount ($)",
        field: "amount",
    },
    status: {
        width: 150,
        label: "Status",
        field: "status",
    },
    description: {
        width: 250,
        label: "Description",
        field: "description",
    },
};

const newRowTemplate = {
    uuid: crypto.randomUUID(),
    session_uuid: "",
    stripe_id: "",
    status: "",
    description: "",
};

const customRenderers = {
    uuid: uuidRenderer,
    session_uuid: uuidRenderer,
    id: readOnlyRenderer(),
    client_email: readOnlyRenderer(),
    amount: readOnlyRenderer(),
    status: readOnlyRenderer(true),
};

function ManagePayments() {
    return (
        <ManageAbstract
            recordType="payment"
            title="Payments"
            columnConfig={columnConfig}
            newRowTemplate={newRowTemplate}
            customRenderers={customRenderers}
            primaryEmailField="client_email"
            identifierField="uuid"
            requiredFields={["session_uuid", "stripe_id", "status"]}
        />
    );
}

export default ManagePayments;
