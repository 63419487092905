import React, { createContext, useState, useContext, useEffect } from "react";
import GenericAsync from "../backend/GenericAsync";
import { useAuth } from "./AuthContext";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const { authEmail, authInfo } = useAuth();
    const [profile, setProfile] = useState({});

    const [userInfo, setUserInfo] = useState({});

    const [studentDict, setStudentDict] = useState({});
    const [tutorDict, setTutorDict] = useState({});
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const fetchUserRelations = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/retrieve-user-relations`,
                dataToSend: {
                    userEmail: authEmail,
                },
            });

            // Safely access profile properties
            const firstName = authInfo?.profile?.first_name || "";
            const lastName = authInfo?.profile?.last_name || "";
            const fullName =
                firstName || lastName
                    ? `${firstName} ${lastName}`.trim()
                    : "User";

            const updatedStudentDict = {
                [authEmail]: fullName,
                ...response.user_students,
            };
            setStudentDict(updatedStudentDict);

            const updatedTutorDict = {
                [authEmail]: fullName,
                ...response.tutors,
            };
            setTutorDict(updatedTutorDict);
        } catch (error) {
            console.error(error.message);
        }
    };

    // Load initial user data and relations
    useEffect(() => {
        if (authEmail && authInfo?.profile) {
            fetchUserRelations();

            // Safely access first_name and last_name properties
            const firstName = authInfo.profile.first_name || "";
            const lastName = authInfo.profile.last_name || "";
            const name =
                firstName || lastName
                    ? `${firstName} ${lastName}`.trim()
                    : "User";

            setProfile({
                email: authEmail,
                name: name,
                tutorMode: false,
            });
        }
    }, [authEmail, authInfo]);

    const fetchUserInfo = async () => {
        if (profile.email) {
            if (profile.email !== authEmail) {
                try {
                    const response = await GenericAsync({
                        backendUrl: `${backendUrl}/api/auth/get-user`,
                        dataToSend: {
                            email: profile.email,
                        },
                    });
                    setUserInfo(response.user_info);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            } else {
                setUserInfo(authInfo);
            }
        }
    };

    useEffect(() => {
        fetchUserInfo();
    }, [profile.email]);

    const selectUserProfile = async (studentEmail, studentName) => {
        const tutorMode = studentEmail !== authEmail;

        try {
            // Update profile with the selected student's data
            setProfile({
                email: studentEmail,
                name: studentName,
                tutorMode: tutorMode,
            });
        } catch (error) {
            console.error(error.message);
            // Fallback to basic profile update if backend request fails
            setProfile({
                email: studentEmail,
                name: studentName,
                tutorMode: tutorMode,
            });
        }
    };

    return (
        <UserContext.Provider
            value={{
                profile,
                userInfo,
                studentDict,
                setStudentDict,
                tutorDict,
                setTutorDict,
                selectUserProfile,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};
