import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { useStytch } from "@stytch/react";
import Home from "./components/tutoring/Home";
import MyInfo from "./components/tutoring/MyInfo";
import StripeCallback from "./components/tutoring/StripeCallback";
import "./components/tutoring/css/TutoringStyles.css";
import TermsOfService from "./components/tutoring/PrivacyPolicy";
import { useAuth } from "./components/shared/auth/AuthContext";
import NotFound from "./components/shared/auth/NotFound";
import { handleLogout } from "./components/shared/auth/LogoutButton";
import ConfirmModal from "./components/shared/popups/ConfirmModal";
import GenericAsync from "./components/shared/backend/GenericAsync";
import { getBasePath } from "./components/shared/utils/DomainUtils";
import Dashboard from "./components/admin/Dashboard";
import Payouts from "./components/tutoring/Payouts";
import { useTutoringUser } from "./components/shared/auth/TutoringContext";

function Tutoring() {
    const { authEmail, authInfo } = useAuth();
    const { userInfo, userArray, activeIndex, selectUser } = useTutoringUser();

    const stytchClient = useStytch();
    const [requestSent, setRequestSent] = useState(false);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    // Get the base path for tutoring
    const basePath = getBasePath("tutoring");

    const handleTutorSelect = (event) => {
        const ix = parseInt(event.target.value);
        selectUser(ix);
    };

    if (!authInfo) {
        return <div>Loading...</div>;
    }

    const handleRequestAccess = async () => {
        try {
            setRequestSent(true);
            await GenericAsync({
                backendUrl: `${backendUrl}/api/auth/request-tutor-access`,
                dataToSend: { email: authEmail },
            });
        } catch (error) {
            console.error("Failed to request access:", error);
        }
    };

    // Show modal if user is not an admin or tutor
    if (
        authInfo?.role !== "admin" &&
        authInfo?.role !== "tutor" &&
        authInfo?.role !== "referrer"
    ) {
        return (
            <ConfirmModal
                isOpen={true}
                onConfirm={
                    requestSent
                        ? () =>
                              (window.location.href =
                                  "https://aliaeducation.com")
                        : handleRequestAccess
                }
                onClose={
                    requestSent
                        ? null
                        : () =>
                              (window.location.href =
                                  "https://aliaeducation.com")
                }
                message={
                    requestSent
                        ? "Thanks! The Alia team will review your request and update you accordingly."
                        : "You need tutor access to view this section."
                }
                confirmMessage={
                    requestSent ? "Return to Home Page" : "Request Access"
                }
                declineMessage={!requestSent ? "Return to Home Page" : null}
                showActions={true}
            />
        );
    }

    return (
        <div className="tutoring-layout">
            <nav className="tutoring-sidebar">
                <ul className="tutoring-menu">
                    <li className="tutoring-menu-item">
                        <Link className="tutoring-menu-link" to={`${basePath}`}>
                            Home
                        </Link>
                    </li>
                    <li className="tutoring-menu-item">
                        <Link
                            className="tutoring-menu-link"
                            to={`${basePath}/my-info`}
                        >
                            My Info
                        </Link>
                    </li>
                    {userInfo?.role !== "referrer" ? (
                        <li className="tutoring-menu-item">
                            <Link
                                className="tutoring-menu-link"
                                to={`${basePath}/dashboard/sessions`}
                            >
                                Dashboard
                            </Link>
                        </li>
                    ) : (
                        <li className="tutoring-menu-item">
                            <Link
                                className="tutoring-menu-link"
                                to={`${basePath}/dashboard/relationships`}
                            >
                                Dashboard
                            </Link>
                        </li>
                    )}
                    <li className="tutoring-menu-item">
                        <Link
                            className="tutoring-menu-link"
                            to={`${basePath}/payouts`}
                        >
                            Payouts
                        </Link>
                    </li>
                    <li className="tutoring-menu-item">
                        <button
                            className="tutoring-menu-link"
                            onClick={() => handleLogout(stytchClient)}
                        >
                            Logout
                        </button>
                    </li>
                </ul>

                {userArray && userArray.length > 0 && (
                    <div className="admin-selector-container">
                        <select
                            onChange={handleTutorSelect}
                            value={activeIndex}
                            className="admin-selector"
                        >
                            {userArray.map((user, index) => {
                                // Capitalize organization
                                const organization = user.organization
                                    ? user.organization
                                          .charAt(0)
                                          .toUpperCase() +
                                      user.organization.slice(1)
                                    : "";

                                return (
                                    <option key={user.uuid} value={index}>
                                        {user.name || user.email} (
                                        {organization})
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}
            </nav>
            <main className="tutoring-content">
                <Routes>
                    <Route path={`${basePath}/`} element={<Home />} />
                    <Route path={`${basePath}/my-info`} element={<MyInfo />} />
                    <Route
                        path={`${basePath}/dashboard/*`}
                        element={<Dashboard tutoringPlatform={true} />}
                    />
                    <Route path={`${basePath}/payouts`} element={<Payouts />} />
                    <Route
                        path={`${basePath}/stripe-callback/*`}
                        element={<StripeCallback />}
                    />
                    <Route
                        path={`${basePath}/privacy-policy`}
                        element={<TermsOfService />}
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </main>
        </div>
    );
}

export default Tutoring;
