import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import { AuthProvider } from "./components/shared/auth/AuthContext";
import AuthWrapper from "./components/shared/auth/AuthWrapper";
import { UserProvider } from "./components/shared/auth/UserContext";
import { TutoringProvider } from "./components/shared/auth/TutoringContext";
import { updateIntercomVisibility } from "./components/shared/common/intercom/IntercomUtils";

import Website from "./Website";
import App from "./App";
import Generative from "./Generative";
import Tutoring from "./Tutoring";
import Admin from "./Admin";

import "./components/shared/utils/GoogleAnalytics";
import {
    isMainDomain,
    isGenerativeDomain,
    isTutoringDomain,
    isAdminDomain,
} from "./components/shared/utils/DomainUtils";

import { sendErrorToSlack } from "./components/shared/slack/SlackAlert";

// Global error handler
let isHandlingError = false; // Flag to prevent duplicate error handling

const errorHandler = (error) => {
    // Prevent duplicate error handling in the same cycle
    if (isHandlingError) {
        console.log("Already handling an error, skipping duplicate");
        return;
    }

    isHandlingError = true;

    // Send to Slack - do this only once
    sendErrorToSlack(error, "GlobalErrorHandler");

    // Redirect to error page
    if (window.location.pathname !== "/error") {
        try {
            window.location.href = "/error";
        } catch (redirectError) {
            console.error("Failed to redirect to error page:", redirectError);
            alert("An unexpected error occurred. Please refresh the page.");
        }
    } else {
        // We're already on the error page and another error occurred
        console.error("Error occurred on error page:", error);
        alert("An unexpected error occurred. Please refresh the page.");
    }

    // Reset the error handling flag after a short delay
    setTimeout(() => {
        isHandlingError = false;
    }, 1000);
};

// Component to handle Intercom visibility
function IntercomManager({ children }) {
    const location = useLocation();

    useEffect(() => {
        const intercomEnabledPaths = [
            "https://sat.aliaeducation.com",
            "https://aliaeducation.com",
        ];
        const shouldShowIntercom = intercomEnabledPaths.includes(
            window.location.origin,
        );
        updateIntercomVisibility(shouldShowIntercom);
    }, [location]);

    return children;
}

// Helper function to get the root domain for cookies
const getRootDomain = () => {
    const hostname = window.location.hostname;

    // Handle localhost
    if (hostname.includes("localhost") || hostname.includes("127.0.0.1")) {
        return "localhost";
    }

    // Handle ngrok and other dev URLs
    if (
        hostname.includes("ngrok-free.app") ||
        hostname.includes("dev.sat.aliaeducation.com")
    ) {
        return hostname;
    }

    // For aliaeducation.com and its subdomains, use aliaeducation.com
    if (hostname.includes("aliaeducation.com")) {
        return "aliaeducation.com";
    }

    // Default to the current hostname if nothing else matches
    return hostname;
};

const stytchOptions = {
    cookieOptions: {
        opaqueTokenCookieName: "stytch_session",
        jwtCookieName: "stytch_session_jwt",
        path: "/",
        availableToSubdomains: true,
        domain: getRootDomain(),
    },
};

const stytchClient = new StytchUIClient(
    process.env.REACT_APP_STYTCH_PUBLIC_TOKEN,
    stytchOptions,
);

// Handle runtime errors
window.addEventListener("error", errorHandler);

// Handle unhandled promise rejections
window.addEventListener("unhandledrejection", (event) => {
    errorHandler(event.reason);
});

const root = createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <IntercomManager>
            {isMainDomain() ? (
                <Website />
            ) : (
                <StytchProvider stytch={stytchClient}>
                    <AuthProvider>
                        <AuthWrapper>
                            <UserProvider>
                                {isGenerativeDomain() ? (
                                    <Generative />
                                ) : isTutoringDomain() ? (
                                    <TutoringProvider>
                                        <Routes>
                                            <Route
                                                path="/*"
                                                element={<Tutoring />}
                                            />
                                        </Routes>
                                    </TutoringProvider>
                                ) : isAdminDomain() ? (
                                    <TutoringProvider>
                                        <Routes>
                                            <Route
                                                path="/*"
                                                element={<Admin />}
                                            />
                                        </Routes>
                                    </TutoringProvider>
                                ) : (
                                    <App />
                                )}
                            </UserProvider>
                        </AuthWrapper>
                    </AuthProvider>
                </StytchProvider>
            )}
        </IntercomManager>
    </BrowserRouter>,
);
