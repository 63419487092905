import React from "react";

const TermsAndConditionsText = () => {
    return (
        <div className="terms-body">
            <p>
                These Alia Education Platform Terms of Use and License Agreement
                contain the terms, covenants, conditions, and provisions
                (collectively the <strong>Terms</strong>) upon which you may
                access and use the Alia Education Platform.
            </p>

            <h3>1. DEFINITIONS</h3>
            <p>
                <strong>&quot;Authorized Users&quot;</strong> means individual
                employees, independent contractors and students of Customer who
                have a need, and are authorized by Customer, to use the
                Platform.
            </p>
            <p>
                <strong>&quot;Customer Data&quot;</strong> shall mean any and
                all content and materials that Customer and its Authorized Users
                create through features of the Service, as well as any data
                imported by Customer into the Platform.
            </p>
            <p>
                <strong>&quot;Documentation&quot;</strong> means the user
                guides, technical documentation and/or systems requirements, as
                updated by Alia Education from time to time, generally made
                available to Customer.
            </p>
            <p>
                <strong>&quot;Personally Identifiable Information&quot;</strong>{" "}
                is any data that can be used to identify a specific individual,
                including but not limited to, a person&apos;s first name and
                last name, in combination with any one or more of the following
                data elements:
            </p>
            <ul>
                <li>Social Security number;</li>
                <li>
                    Driver&apos;s license number or state-issued identification
                    card number; or
                </li>
                <li>
                    Financial account number, or credit or debit card number,
                    with or without any required security code, access code,
                    personal identification number or password;
                </li>
                <li>IP or other geolocation data; or</li>
                <li>biometric and health data.</li>
            </ul>
            <p>
                Provided, however, that &quot;Personally Identifiable
                Information&quot; shall not include information that is lawfully
                obtained from publicly available sources or from government
                records available to the general public.
            </p>
            <p>
                <strong>&quot;Platform&quot;</strong> shall mean Alia
                Education&apos;s proprietary, Internet-delivered platform of
                servers, software, and related technology through which Alia
                Education offers the Services.
            </p>
            <p>
                <strong>&quot;Services&quot;</strong> shall mean Alia
                Education&apos;s services, including a user-facing web
                application (the
                <strong>&quot;Web Application&quot;</strong>) for, among other
                things, educational content and learning management.
            </p>

            <h3>2. USE OF SERVICES</h3>
            <p>
                <u>Right of Access.</u> Subject to Customer&apos;s full payment
                of all applicable fees, Alia Education grants to Customer a
                limited, non-exclusive, non-transferable, worldwide right during
                the Term to access and use the Platform in accordance with these
                Terms.
            </p>
            <p>
                <u>Restrictions.</u> Customer shall not permit use of or access
                to the Platform by anyone other than its Authorized Users.
                Without limitation, Customer shall not, and shall not allow any
                third party to: (i) copy, modify, adapt or create derivative
                works of the Platform or documentation; (ii) reverse engineer,
                decompile, disassemble or otherwise attempt to derive the source
                code for the Platform; (iii) sell, resell, license, sublicense,
                distribute, rent or lease the Platform or include the Platform
                in a service bureau or outsourcing offering; or (iv) remove or
                alter any trademark, logo, copyright or other proprietary
                notices associated with the Platform.
            </p>
            <p>
                <u>Suspension of Services.</u> Alia Education may immediately
                suspend Customer&apos;s access to or use of the Services and/or
                Platform without notice if: (i) Customer or any of its
                Authorized Users violate the Terms; (ii) Customer fails to pay
                any fees when due; (iii) Customer&apos;s use of the Services
                disrupts or poses a security risk to Alia Education or to other
                customers; or (iv) Alia Education reasonably determines that
                suspension is necessary to prevent imminent harm to Alia
                Education&apos;s systems.
            </p>
            <p>
                <u>Authorized Users.</u> Upon full payment for a subscription
                hereunder, or upon activation of a limited trial access period,
                Alia Education will create a master account (
                <strong>&quot;Master Account&quot;</strong>) for Customer to
                access the Platform, which may use to access the Platform and
                Services.
            </p>
            <p>
                <u>Service Usage Limitations.</u> Customers may be subject to
                service usage limitations. Any such limitation(s) will be
                applicable and enforced at the sole discretion of Alia Education
                and will be communicated to Customer.
            </p>

            <h3>3. WARRANTIES</h3>
            <p>
                <u>Alia Education Warranty.</u> Alia Education warrants that,
                subject to the terms hereof: (i) it owns or has the right to use
                all intellectual property required by the Platform, Software,
                and Services; (ii) the Services will be performed in a
                professional manner consistent with industry standards; and
                (iii) to the best of Alia Education&apos;s knowledge, the
                Platform does not contain any virus, malicious code or disabling
                device.
            </p>
            <p>
                <u>Warranty Disclaimer.</u> As an Internet-delivered software
                application, the Platform and the Services may experience
                periods of downtime, including but not limited to scheduled
                maintenance. THE PLATFORM IS PROVIDED &quot;AS IS&quot; AND ALIA
                EDUCATION DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT.
            </p>
            <p>
                <u>Customer Warranties.</u> Customer represents and warrants
                that: (i) Customer has the legal right to copy, publish, share,
                store, or otherwise use any Customer Data used, transferred, or
                processed in connection with the Platform; and (ii) Customer
                Data does not infringe the intellectual property rights of, or
                otherwise harm, any third party.
            </p>
            <p>
                <u>Mutual Warranties.</u> Each party to this Agreement
                represents and warrants to the other that it has the right and
                authority to enter into this Agreement and to perform its
                obligations hereunder.
            </p>

            <h3>4. INDEMNIFICATION</h3>
            <p>
                <u>Alia Education Indemnification.</u> Provided that complete
                payment from Customer has been received, Alia Education shall
                defend Customer against claims brought against Customer by any
                third party alleging that Customer&apos;s authorized use of the
                Platform infringes such third party&apos;s United States
                intellectual property rights.
            </p>
            <p>
                <u>Customer Indemnification.</u> To the extent permitted by
                applicable law, Customer shall indemnify and hold harmless Alia
                Education, its directors, officers, employees, agents, and
                representatives, from and against any and all third-party claims
                arising from or related to: (i) Customer&apos;s use of the
                Platform in violation of these Terms; (ii) Customer Data; or
                (iii) Customer&apos;s breach of any warranty in these Terms.
            </p>

            <h3>5. CONFIDENTIALITY</h3>
            <p>
                <u>Obligations.</u> &quot;Confidential Information&quot; means
                any software, inventions, know-how, business plans, product
                designs, technical and financial information exchanged under
                these Terms. Each party agrees to maintain the confidentiality
                of the other party&apos;s Confidential Information and to use
                such Confidential Information only as necessary to perform its
                obligations under these Terms.
            </p>
            <p>
                <u>Exceptions.</u> These confidentiality obligations do not
                apply to (and Confidential Information does not include)
                information that: (i) is or becomes public knowledge through no
                fault of the receiving party; (ii) was known by the receiving
                party prior to receipt from the disclosing party; (iii) is
                rightfully obtained from a third party without breach of any
                confidentiality obligation; or (iv) is independently developed
                by the receiving party without use of the disclosing
                party&apos;s Confidential Information.
            </p>

            <h3>6. DATA COLLECTION AND SECURITY</h3>
            <p>
                <u>Data Security.</u> Alia Education shall use commercially
                reasonable security precautions intended to protect against
                unauthorized access to Customer data stored by Alia Education in
                connection with the Services.
            </p>
            <p>
                <u>Customer Data.</u> Customer agrees that to the extent that
                the Platform allows Customer to create content, Customer is
                solely responsible for the design, creation, and use of such
                content, including ensuring that it complies with all applicable
                laws.
            </p>
            <p>
                <u>Personally Identifiable Information (&quot;PII&quot;).</u>{" "}
                Customer is responsible at all times for ensuring the
                protection, security, and destruction of any PII stored,
                processed, or transmitted by Customer or Customer&apos;s
                Authorized Users through the Platform.
            </p>
            <p>
                <u>Limited License to Customer Data.</u> Customer owns the
                Customer Data. However, during the term of this Agreement,
                Customer grants Alia Education a non-exclusive, worldwide,
                royalty-free license to use, copy, store, transmit and display
                Customer Data solely as necessary to provide the Services.
            </p>
            <p>
                <u>Use of De-Identified, Aggregated Data.</u> To the extent
                permitted by applicable law, Customer grants Alia Education the
                perpetual right to use, process, transfer, and store any
                Customer Data, in a de-identified, aggregated manner, to
                improve, develop and enhance the Services and for other lawful
                business purposes.
            </p>

            <h3>7. OWNERSHIP</h3>
            <p>
                <u>Alia Education.</u> Alia Education and its licensors
                exclusively own and reserve all rights, title, and interest in
                and to all of the Services, Platform, and Documentation. No
                right, title or interest in the Platform is transferred to
                Customer by virtue of these Terms.
            </p>
            <p>
                <u>Customer.</u> Customer retains all right, title and interest
                in any data, files, attachments, text, images, and other content
                that Customer or a Customer Authorized User uploads to the
                Platform or that is processed, shared, or stored using the
                Platform.
            </p>

            <h3>8. LIMITATION OF LIABILITY</h3>
            <p>
                <u>Limitations.</u> TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN
                NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY FOR ANY
                INDIRECT, CONSEQUENTIAL, INCIDENTAL, PUNITIVE, OR SPECIAL
                DAMAGES, INCLUDING BUT NOT LIMITED TO ANY LOST PROFITS, LOST
                SAVINGS, LOSS OF USE, LOSS OF DATA, OR BUSINESS INTERRUPTION.
                ALIA EDUCATION&apos;S TOTAL LIABILITY ARISING OUT OF OR RELATED
                TO THIS AGREEMENT WILL NOT EXCEED THE AMOUNT PAID BY CUSTOMER TO
                ALIA EDUCATION DURING THE TWELVE (12) MONTHS PRECEDING THE EVENT
                GIVING RISE TO THE LIABILITY.
            </p>
            <p>
                <u>Exceptions.</u> The obligations and exclusions set forth in
                this Section shall not apply to: (i) a party&apos;s indemnity
                obligations under Section 4, (ii) a violation of a party&apos;s
                intellectual property rights, or (iii) a party&apos;s breach of
                its confidentiality obligations under Section 5.
            </p>

            <h3>9. THIRD-PARTY SERVICES</h3>
            <p>
                The Platform may be available through, or in connection with,
                independent third-party service providers. When Customer uses
                such third-party services in connection with the Platform,
                Customer is responsible for complying with any terms and
                conditions applicable to such third-party services. Alia
                Education is not responsible for any third-party services or for
                any act or omission of any third-party service provider.
            </p>

            <h3>10. PAYMENT</h3>
            <p>
                <u>Fees.</u> Customer shall pay to Alia Education the
                subscription fees in the amount and for the subscription
                duration set forth in an applicable order for Services (the
                &quot;Subscription Fees&quot;). All Subscription Fees are
                non-refundable except as expressly provided in these Terms.
            </p>
            <p>
                <u>Payment.</u> Customer agrees to pay all Subscription Fees as
                set forth in the applicable order.
            </p>
            <p>
                <u>Taxes.</u> All fees are exclusive of, and Customer is
                responsible for, all taxes, charges, and duties applicable to
                each transaction relating to the Platform, including sales, use,
                excise, value-added, and other similar taxes.
            </p>
            <p>
                <u>Upgrade Fees.</u> If Customer chooses to upgrade to access
                additional features of other Alia Education subscription
                services for which separate or additional fees apply and which
                are not otherwise included in Customer&apos;s subscription,
                Customer will be charged accordingly.
            </p>

            <h3>11. TERM AND TERMINATION</h3>
            <p>
                <u>Term and Renewal.</u> Following expiration of the initial
                subscription term, whether monthly, annually, or multi-year,
                (the &quot;Initial Subscription Term&quot;) Customer&apos;s
                subscription will automatically renew for additional periods
                equal in length to the Initial Subscription Term, unless either
                party gives the other party written notice of non-renewal at
                least thirty (30) days prior to the end of the then-current
                term.
            </p>
            <p>
                <u>Termination.</u> Either party may terminate these Terms (a)
                upon thirty (30) days prior written notice if the other party
                has materially breached these Terms and failed to cure such
                breach within the thirty (30) day period, or (b) immediately, if
                the other party becomes insolvent or bankrupt, liquidated or is
                dissolved, or ceases substantially all of its business.
            </p>
            <p>
                <u>Effect of Termination.</u> Upon termination, non-renewal,
                cancellation or expiration of the Term or any limited trial
                access period, all rights granted by Alia Education under these
                Terms will immediately cease, including all rights of access and
                use of the Platform.
            </p>
            <p>
                <u>Treatment of Customer Data at Termination.</u> Upon
                Customer&apos;s written request, which must be received no later
                than thirty (30) days prior to the effective date of
                termination, Alia Education shall make available to Customer a
                copy of Customer Data in a mutually agreed format. After such
                thirty (30) day period, Alia Education shall have no obligation
                to maintain or provide any Customer Data.
            </p>
            <p>
                <u>Survival.</u> The following sections will survive termination
                or expiration of these Terms and Customer subscription: Sections
                2, 4, 5, 6, 7, 8, 10, and 12.
            </p>

            <h3>12. GENERAL</h3>
            <p>
                <u>Export Compliance.</u> Customer may not, and shall not permit
                its Authorized Users to, access, use, export or re-export
                Customer Data, in or to any U.S.-embargoed countries or in
                violation of any U.S. export law or regulation.
            </p>
            <p>
                <u>Publicity.</u> Customer agrees that Alia Education may list
                Customer and a Customer-approved logo on Alia Education&apos;s
                websites and marketing materials or press releases, subject to
                any trademark or logo usage guidelines that Customer provides to
                Alia Education.
            </p>
            <p>
                <u>No Third-Party Beneficiaries.</u> Nothing in this Agreement
                shall be construed as giving any right, remedy or claim
                hereunder to any person or entity that is not a party hereto.
                Any persons who are not parties to these Terms shall have no
                right to enforce any part of these Terms.
            </p>
            <p>
                <u>Assignment.</u> Alia Education may freely assign or delegate
                all rights and obligations under these Terms, fully or
                partially, with or without notice to Customer. Alia Education
                may also assign this Agreement to any affiliate, or in
                connection with any sale, reorganization, merger, or other
                business transaction. Customer may not assign or transfer its
                rights or obligations under these Terms without the prior
                written consent of Alia Education.
            </p>
            <p>
                <u>Force Majeure.</u> Except for payment obligations, neither
                party shall be deemed in default under these Terms as a result
                of any delay or failure to perform due to causes beyond the
                reasonable control of that party, including but not limited to
                acts of God, labor disputes, shortages, war, acts of terrorism
                and governmental regulations.
            </p>
            <p>
                <u>Entire Agreement.</u> These Terms and the Alia Education
                Privacy Policy, incorporated herein by this reference,
                constitute the complete and entire agreement between the parties
                regarding the subject matter hereof and supersedes any prior
                discussions, proposals, negotiations, or discussions.
            </p>
            <p>
                <u>Governing Law.</u> These Terms shall be governed, construed,
                and enforced in accordance with the laws of the State of New
                York without reference to conflicts of law principles. Any legal
                action, suit, or proceeding related to this Agreement shall be
                brought exclusively in the federal or state courts located in
                New York, New York.
            </p>
            <p>
                <u>Miscellaneous.</u> Any rule of construction to the effect
                that ambiguities are to be resolved against the drafting party
                will not be applied in the construction or interpretation of
                these Terms. Any waiver of any part of these Terms must be in
                writing and shall not constitute a waiver of any other part or
                any future waiver of the same part.
            </p>
            <p>
                For the complete Terms and Conditions, please contact us at
                info@aliaeducation.com.
            </p>
        </div>
    );
};

export default TermsAndConditionsText;
