import { useSettings } from "../../auth/SettingsContext";
import formatString from "../../utils/TextProcessing";
import React, { useEffect, useState } from "react";
import { useUser } from "../../auth/UserContext";
import GenericAsync from "../../backend/GenericAsync";
import ConfirmModal from "../../popups/ConfirmModal";
import { formatDateTimeObject } from "../../utils/FormatUTCTime";

const SubscriptionInfo = () => {
    const { settings, setSettings } = useSettings();
    const [cancelAbility, setCancelAbility] = useState(false);
    const { profile } = useUser();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [modalStage, setModalStage] = useState(1); // 1 for selecting topics, 2 for generating test
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    function returnCancellationText() {
        if (
            "requestedCancellationDate" in settings &&
            settings.requestedCancellationDate
        ) {
            return (
                `Your subscription was cancelled on ${formatDateTimeObject(settings.requestedCancellationDate)}. ` +
                "You will not be charged for any future subscription renewals."
            );
        } else {
            return (
                "To stop your subscription, press the button on the right. " +
                "We'll halt your future renewals effective immediately."
            );
        }
    }

    function returnCancellationModalText() {
        if (modalStage === 1) {
            return (
                "Are you sure you want to cancel your subsciption? If you cancel, you will " +
                "not be able to access your assignments or reports after your current subscription " +
                "period lapses. "
            );
        } else {
            return "Subcription cancelled! We hope to see you back soon.";
        }
    }

    useEffect(() => {
        if (!profile.tutorMode && settings.currentPeriodEnd && !isExpired) {
            setCancelAbility(true);
        } else {
            setCancelAbility(false);
        }
    }, [settings]);

    useEffect(() => {
        if (settings.currentPeriodEnd) {
            const currentDate = new Date();
            const periodEndDate = new Date(settings.currentPeriodEnd);

            setIsExpired(periodEndDate < currentDate);
        }
    }, [settings.currentPeriodEnd]);

    const handleCancellation = () => {
        setShowConfirmModal(true);
    };

    const onClosePopup = () => {
        setShowConfirmModal(false); // Simply close the modal
        setModalStage(1); // Reset to initial stage
    };

    const handleApplyClick = () => {
        window.location.href = "https://aliaeducation.com/apply";
    };

    const sendCancellation = async () => {
        setModalStage(2);
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/submit-cancellation`,
                dataToSend: {
                    userEmail: profile.email,
                    subscription: settings.subscription,
                },
            });
            setSettings({
                ...settings,
                requestedCancellationDate: response.requestedCancellationDate,
            });
        } catch (error) {
            console.log(error.message);
        }
    };

    const renderSubscriptionType = () => {
        if (settings.role !== "client") {
            return (
                <div className={"settings-container-columns"}>
                    <div className="settings-text-column">
                        <div className="settings-column-title">
                            Subscription Type:
                        </div>
                        <div className={"settings-column-content"}>
                            {formatString(settings.role)}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={"settings-container-columns"}>
                    <div
                        className="settings-text-column"
                        style={{
                            flex: "none",
                            width:
                                settings.subscription === "basic" || isExpired
                                    ? "35%"
                                    : "50%",
                        }}
                    >
                        <div className="settings-column-title">
                            Subscription Type:
                        </div>
                        <div className={"settings-column-content"}>
                            {formatString(settings.subscription)}
                            {isExpired && " (Expired)"}
                            {settings.transaction === "invoice.trial_started" &&
                                " (Trial)"}
                        </div>
                    </div>
                    <div className="settings-text-column">
                        {settings.subscription === "basic" || isExpired ? (
                            <>
                                <div className="settings-column-title">
                                    Apply to the Alia SAT Bootcamp:
                                </div>
                                <div
                                    className="settings-column-title"
                                    style={{ fontSize: "14px" }}
                                >
                                    If accepted, you will have unlimited access
                                    to the entirety of the Alia Education
                                    platform, including content curated
                                    specifically for you.
                                    <br></br>
                                </div>
                                <div
                                    className={"settings-column-content"}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <button
                                        className={`orange-button`}
                                        onClick={handleApplyClick}
                                        style={{
                                            margin: "15px 0 0 0",
                                            fontSize: "15px",
                                            fontWeight: "400",
                                            width: "70%",
                                        }}
                                    >
                                        Apply Now
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="settings-column-title">
                                    {settings.subscriptionBanner
                                        ? "Subscription Expiry Date:"
                                        : "Renewal Date:"}
                                </div>
                                <div className={"settings-column-content"}>
                                    {formatDateTimeObject(
                                        settings.currentPeriodEnd,
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            );
        }
    };

    const renderCancellation = () => {
        if (
            settings.role === "client" &&
            settings.currentPeriodEnd &&
            !isExpired &&
            settings.subscription !== "basic"
        ) {
            return (
                <div className={"settings-text-container"}>
                    <div className={"settings-text-title"}>Cancellation:</div>
                    <div
                        className={"settings-container-columns"}
                        style={{ gap: "20px" }}
                    >
                        <div
                            className="settings-text-column"
                            style={{
                                width: settings.requestedCancellationDate
                                    ? "100%"
                                    : "65%",
                                flex: "none",
                            }}
                        >
                            <div
                                className={"settings-column-content"}
                                style={{
                                    fontSize: "14px",
                                    marginTop: "20px",
                                }}
                            >
                                {returnCancellationText()}
                            </div>
                        </div>
                        <div className="settings-text-column">
                            {!settings.requestedCancellationDate && (
                                <button
                                    className={`orange-button ${cancelAbility ? "" : "disabled"}`}
                                    onClick={
                                        cancelAbility
                                            ? handleCancellation
                                            : null
                                    }
                                    disabled={!cancelAbility}
                                    style={{
                                        margin: "0px",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                    }}
                                >
                                    Cancel Subscription
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className="settings-unit">
            <div className={"settings-text-container"}>
                <div className={"settings-container-columns"}>
                    {renderSubscriptionType()}
                </div>
            </div>
            {renderCancellation()}
            <ConfirmModal
                isOpen={showConfirmModal}
                onConfirm={modalStage === 1 ? sendCancellation : onClosePopup}
                onClose={modalStage === 1 ? onClosePopup : null}
                message={returnCancellationModalText()}
                confirmMessage={
                    modalStage === 1 ? "Cancel Subscription" : "Go Back"
                }
                declineMessage="Go Back"
                showActions={true}
                maxWidth="800px"
            />
        </div>
    );
};

export default SubscriptionInfo;
