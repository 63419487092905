import React from "react";

const SocialProofUniversities = () => {
    return (
        <div className="as-container" style={{ backgroundColor: "white" }}>
            <div className="as-content">
                <div className="university-title" style={{ color: "#1e3a8a" }}>
                    Our students have been accepted to some of the top
                    universities in the world
                </div>
                <div className="university-roster">
                    <div className="university-image">
                        <img
                            src="/assets/website/universities/duke.svg"
                            alt="duke"
                        />
                    </div>
                    <div className="university-image">
                        <img
                            src="/assets/website/universities/stanford.svg"
                            alt="stanford"
                        />
                    </div>
                    <div className="university-image">
                        <img
                            src="/assets/website/universities/ut_austin.svg"
                            alt="ut_austin"
                        />
                    </div>
                    <div className="university-image">
                        <img
                            src="/assets/website/universities/notre_dame.svg"
                            alt="notre_dame"
                        />
                    </div>
                    <div className="university-image">
                        <img
                            src="/assets/website/universities/georgetown.svg"
                            alt="georgetown"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialProofUniversities;
