import React, { useState, useCallback, useEffect } from "react";

const ReferenceSheetButton = () => {
    const [isSheetVisible, setIsSheetVisible] = useState(false);
    const [position, setPosition] = useState({ x: 20, y: 100 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const [size, setSize] = useState({ width: 800, height: "auto" });
    const [isResizing, setIsResizing] = useState(false);

    const handleMouseDown = (e) => {
        if (e.target.className === "resize-handle") {
            setIsResizing(true);
            return;
        }
        setIsDragging(true);
        setDragOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
    };

    const handleMouseMove = useCallback(
        (e) => {
            if (isDragging) {
                setPosition({
                    x: e.clientX - dragOffset.x,
                    y: e.clientY - dragOffset.y,
                });
            } else if (isResizing) {
                const newWidth = Math.max(300, e.clientX - position.x);
                setSize((prev) => ({
                    ...prev,
                    width: newWidth,
                }));
            }
        },
        [isDragging, isResizing, dragOffset, position.x],
    );

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
        setIsResizing(false);
    }, []);

    useEffect(() => {
        if (isDragging || isResizing) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        }
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging, isResizing, handleMouseMove, handleMouseUp]);

    return (
        <div className="hide-on-mobile">
            <button
                onClick={() => setIsSheetVisible(!isSheetVisible)}
                className="header-button"
            >
                Reference Sheet
            </button>
            {isSheetVisible && (
                <div
                    onMouseDown={handleMouseDown}
                    style={{
                        position: "fixed",
                        left: `${position.x}px`,
                        top: `${position.y}px`,
                        zIndex: 1000,
                        background: "white",
                        border: "2px solid #016dea",
                        borderRadius: "8px",
                        padding: "15px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        cursor: isDragging ? "grabbing" : "grab",
                        width: `${size.width}px`,
                        maxWidth: "90vw",
                        maxHeight: "80vh",
                        overflow: "hidden",
                        userSelect: "none",
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "white",
                        }}
                    >
                        <img
                            src="/assets/dsat_reference_sheet.svg"
                            alt="Reference Sheet"
                            style={{
                                width: "100%",
                                height: "auto",
                                display: "block",
                                pointerEvents: "none",
                            }}
                        />
                        <button
                            onClick={() => setIsSheetVisible(false)}
                            className="orange-button"
                            style={{
                                margin: "10px auto 0",
                                padding: "8px 16px",
                                width: "auto",
                            }}
                        >
                            Hide
                        </button>
                        <div
                            className="resize-handle"
                            style={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                width: "20px",
                                height: "20px",
                                cursor: "se-resize",
                                background: `linear-gradient(135deg, transparent 50%, #016dea 50%)`,
                                borderBottomRightRadius: "0px",
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReferenceSheetButton;
