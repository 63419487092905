import React, { useState, useEffect } from "react";
import { useRecordManagement } from "../admin/hooks/useRecordManagement";
import { useNavigate } from "react-router-dom";
import { useTutoringUser } from "../shared/auth/TutoringContext";
import "./css/TutoringStyles.css";

function Home() {
    const { userInfo } = useTutoringUser();
    const { loadRecord } = useRecordManagement("tutor");
    const [missingFields, setMissingFields] = useState([]);
    const navigate = useNavigate();

    const isTutoringSubdomain =
        window.location.hostname.startsWith("tutoring.");

    // Base path will be empty for subdomain, '/tutoring' for development
    const basePath = isTutoringSubdomain ? "" : "/tutoring";

    useEffect(() => {
        if (userInfo?.email && userInfo?.uuid) {
            loadRecord("tutor", userInfo?.uuid)
                .then((data) => {
                    if (Array.isArray(data) && data.length > 0) {
                        const tutor = data[0];
                        const missing = [];

                        if (!tutor.first_name) missing.push("First Name");
                        if (!tutor.last_name) missing.push("Last Name");
                        if (!tutor.stripe_id) missing.push("Stripe Connection");

                        if (userInfo.role == "tutor") {
                            if (!tutor.zoom_user_id)
                                missing.push("Zoom Connection");
                            if (!tutor.calendar_connected)
                                missing.push("Google Calendar Connection");
                        }

                        setMissingFields(missing);
                    }
                })
                .catch(console.error);
        }
    }, [userInfo?.uuid, location.key]);

    const getPlatformDescription = () => {
        const { userInfo } = useTutoringUser();
        const isReferrer = userInfo?.role === "referrer";

        return (
            <div className="tutoring-platform-description">
                <h2>Platform Features</h2>

                <div className="section-container">
                    <div className="tutoring-feature-item">
                        <h3>1. My Info</h3>
                        <p>
                            This tab is where you&apos;ll see your personal
                            information.
                        </p>
                    </div>

                    <div className="tutoring-feature-item">
                        <h3>2. Dashboard</h3>
                        <p>
                            Manage your{" "}
                            {!isReferrer ? "sessions, clients, and " : ""}{" "}
                            relationships via this table.
                        </p>

                        {!isReferrer && (
                            <>
                                <h4 style={{ marginTop: "1rem" }}>Sessions</h4>
                                <ul>
                                    <li>
                                        Your scheduled sessions will appear
                                        here.
                                    </li>
                                    <li>
                                        After each session, update its status in
                                        the Sessions tab to
                                        &quot;completed&quot;,
                                        &quot;no-show&quot;, or &quot;late
                                        cancel&quot;.
                                    </li>
                                    <li>
                                        Click &quot;Pay Out&quot; and confirm to
                                        create a Stripe payment intent. Payments
                                        are processed the following morning at
                                        9am and reach your bank account 1-2 days
                                        later.
                                    </li>
                                </ul>

                                <h4>Clients</h4>
                                <p>
                                    You&apos;ll only be able to schedule
                                    sessions with your listed clients.
                                </p>
                                <ul>
                                    <li>Verify that your client is listed.</li>
                                    <li>
                                        Confirm reporting emails are correct for
                                        automated follow-up emails (coming
                                        soon).
                                    </li>
                                </ul>
                            </>
                        )}

                        <h4>Relationships</h4>
                        {isReferrer ? (
                            <>
                                <p>
                                    These are the relationships that we have
                                    between tutors and clients based on your
                                    referrals.
                                </p>
                                <ul>
                                    <li>
                                        Here you can see the relationships
                                        listed, along with your payments split.
                                    </li>
                                </ul>
                            </>
                        ) : (
                            <>
                                <p>
                                    These define all connections you have with
                                    your clients. This tab is split from Clients
                                    since there can be multiple relationships
                                    per client.
                                </p>
                                <ul>
                                    <li>View hourly rates for each client.</li>
                                    <li>
                                        See brief descriptions of what each
                                        relationship is focused on.
                                    </li>
                                    <li>
                                        Notify Terry or Rishi if/when you have
                                        new clients or relationship changes.
                                    </li>
                                </ul>
                            </>
                        )}
                    </div>
                    <div className="tutoring-feature-item">
                        <h3>3. Payouts</h3>
                        <p>Monitor your earnings through the Payouts tab:</p>
                        <ul>
                            <li>
                                View earnings over any time period you select.
                            </li>
                            <li>
                                See which specific{" "}
                                {isReferrer ? "referrals" : "tutoring sessions"}{" "}
                                contributed to each payout.
                            </li>
                        </ul>
                    </div>
                </div>

                {!isReferrer && (
                    <div className="section-container">
                        <div className="tutoring-feature-item">
                            <h3>Scheduling Sessions</h3>
                            <p>
                                Scheduling happens via Google Calendar and your
                                personal Gmail account.
                            </p>

                            <h4>Steps to schedule a new session:</h4>
                            <ol>
                                <li>
                                    <strong>
                                        Connect your email to the Alia Calendar
                                        App
                                    </strong>
                                    <ul>
                                        <li>
                                            Navigate to My Info, select
                                            &quot;Connect to Google
                                            Calendar&quot;, and complete the
                                            workflow.
                                        </li>
                                        <li>
                                            This allows us to track calendar
                                            events and update them with Zoom
                                            links.
                                        </li>
                                        <li>This is a one-time connection.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>
                                        Connect your email to our Zoom
                                        organization
                                    </strong>
                                    <ul>
                                        <li>
                                            This provides you with a
                                            Professional Zoom license.
                                        </li>
                                        <li>
                                            This is also a one-time connection.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>
                                        Create an event using Google Calendar
                                    </strong>
                                    <ul>
                                        <li>
                                            <strong>
                                                Create a new event at{" "}
                                                <a href="https://calendar.google.com">
                                                    calendar.google.com
                                                </a>
                                                :
                                            </strong>
                                            <ol>
                                                <li>
                                                    Specify the time and date of
                                                    the session.
                                                </li>
                                                <li>
                                                    For the time being, only
                                                    create one-off events, not
                                                    recurring ones.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <strong>Add guests:</strong>
                                            <ol>
                                                <li>
                                                    Your student&apos;s email
                                                    (same one listed in the
                                                    clients tab).
                                                </li>
                                                <li>
                                                    <code>
                                                        team@aliaeducation.com
                                                    </code>
                                                    .
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <strong>Change permissions:</strong>
                                            <ul>
                                                <li>
                                                    Enable &quot;Guests can
                                                    modify events&quot;.
                                                </li>
                                                <li>
                                                    Consider turning this on
                                                    permanently for convenience.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>
                                                Save the calendar event:
                                            </strong>
                                            <ul>
                                                <li>
                                                    A Zoom link will be
                                                    automatically added to the
                                                    event details.
                                                </li>
                                                <li>
                                                    Verify the event appears in
                                                    the Sessions tab of your
                                                    dashboard.
                                                </li>
                                                <li>
                                                    For clients with multiple
                                                    relationships, confirm the
                                                    correct session type was
                                                    created.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Cancelling events:</strong>
                                    <ul>
                                        <li>
                                            Delete the Google Calendar event to
                                            cancel a session.
                                        </li>
                                        <li>
                                            This will update the Sessions
                                            dashboard table.
                                        </li>
                                    </ul>
                                </li>
                            </ol>

                            <p>
                                <strong>Important Notes:</strong>
                            </p>
                            <ul>
                                <li>
                                    You can only schedule sessions for clients
                                    with established relationships. Contact
                                    Terry or Rishi to update/add clients or
                                    relationships.
                                </li>
                                <li>
                                    Session recording is automatically enabled
                                    for all Zoom calls. This allows us to create
                                    automated follow-up emails to send to
                                    students and their parents.
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="tutoring-container">
            <h1 className="tutoring-title">
                Welcome to the Alia Tutoring Platform!
            </h1>
            {missingFields.length > 0 ? (
                <div className="alert-section">
                    <h2>Please Complete Your Profile</h2>
                    <p>The following information needs to be filled out:</p>
                    <ul>
                        {missingFields.map((field) => (
                            <li key={field}>{field}</li>
                        ))}
                    </ul>
                    <p>Please visit My Info to complete your profile setup.</p>
                    <button
                        className="primary-button"
                        onClick={() => navigate(`${basePath}/my-info`)}
                    >
                        Go to My Info
                    </button>
                </div>
            ) : (
                <p>Select an option from the menu to get started.</p>
            )}

            {getPlatformDescription()}

            <div className="section-container">
                <div
                    className="contact-section"
                    style={{ marginBottom: "1rem" }}
                >
                    <div className="contact-container">
                        <h3>Need Help?</h3>
                        <p style={{ marginTop: "1.5rem" }}>
                            If you&apos;re experiencing any issues, have
                            questions about your account, or if anything
                            doesn&apos;t look right, please don&apos;t hesitate
                            to reach out.
                        </p>
                        <p style={{ marginTop: "1.5rem" }}>Contact: Terry</p>
                        <p>Phone: 216-308-4723</p>
                        <p>
                            Email:{" "}
                            <a href="terry@aliaeducation.com">
                                terry@aliaeducation.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
