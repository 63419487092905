import React, { useState } from "react";
import styles from "./SignupStyles.module.css";

export default function StudentInfo({ studentData, setStudentData }) {
    const [touched, setTouched] = useState({
        firstname: false,
        lastname: false,
        email: false,
    });

    const isValidEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleBlur = (field) => {
        setTouched((prev) => ({ ...prev, [field]: true }));
    };

    const getError = (field) => {
        if (!touched[field]) return "";

        switch (field) {
            case "firstname":
                return studentData.firstname.trim() === ""
                    ? "First name is required"
                    : "";
            case "lastname":
                return studentData.lastname.trim() === ""
                    ? "Last name is required"
                    : "";
            case "email":
                if (studentData.email.trim() === "") return "Email is required";
                if (!isValidEmail(studentData.email))
                    return "Please enter a valid email";
                return "";
            default:
                return "";
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.formTitle}>Student Information</div>
            <p className={styles.formDescription}>
                Please fill out the following student information. The email
                below will be used to login to all Alia Education services.
                <br /> <br />
                Note: You can specify a separate billing email on the following
                pages.
            </p>

            <form className={styles.form}>
                <div className={styles.formGroup}>
                    <label htmlFor="firstname">Student First Name</label>
                    <input
                        id="firstname"
                        type="text"
                        value={studentData.firstname}
                        onChange={(e) =>
                            setStudentData({
                                ...studentData,
                                firstname: e.target.value,
                            })
                        }
                        onBlur={() => handleBlur("firstname")}
                        required
                    />
                    {getError("firstname") && (
                        <span className={styles.errorText}>
                            {getError("firstname")}
                        </span>
                    )}
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="lastname">Student Last Name</label>
                    <input
                        id="lastname"
                        type="text"
                        value={studentData.lastname}
                        onChange={(e) =>
                            setStudentData({
                                ...studentData,
                                lastname: e.target.value,
                            })
                        }
                        onBlur={() => handleBlur("lastname")}
                        required
                    />
                    {getError("lastname") && (
                        <span className={styles.errorText}>
                            {getError("lastname")}
                        </span>
                    )}
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="email">Student Email</label>
                    <input
                        id="email"
                        type="email"
                        value={studentData.email}
                        onChange={(e) =>
                            setStudentData({
                                ...studentData,
                                email: e.target.value,
                            })
                        }
                        onBlur={() => handleBlur("email")}
                        required
                    />
                    {getError("email") && (
                        <span className={styles.errorText}>
                            {getError("email")}
                        </span>
                    )}
                </div>
            </form>
        </div>
    );
}
