import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../popups/ConfirmModal";
import { useUser } from "../../auth/UserContext";
import GenericAsync from "../../backend/GenericAsync";
import { useJourney } from "../../common/JourneyContext";

const GenerateModules = () => {
    const navigate = useNavigate();
    const { profile } = useUser();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [selection, setSelection] = useState({
        moduleType: "reading_writing",
        difficulty: "foundations",
        timeMultiplier: "1",
        dueDate: "",
    });
    const { setJourney } = useJourney();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalStage, setModalStage] = useState(1); // 1 for selecting topics, 2 for generating test
    const { generateAbility } = useJourney();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSelection({ ...selection, [name]: value });
    };

    const handleGenerateModule = async () => {
        setModalStage(2); // Switch to generating state

        try {
            const result = await GenericAsync({
                backendUrl: `${backendUrl}/api/generate-new-step`,
                dataToSend: {
                    userEmail: profile.email,
                    stepType: "modules",
                    selection: selection,
                },
            });
            if (result.success) {
                // Navigate to the new module
                const activeStep = result.next_step[0];
                navigate(`/${activeStep.url_ext}`, { state: { activeStep } });

                // Update the journey
                const newJourney = result.journey;
                setJourney(newJourney);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const onClosePopup = () => {
        setShowConfirmModal(false); // Simply close the modal
        setModalStage(1); // Reset to initial stage
    };

    const onPrepareGenerateModule = () => {
        setShowConfirmModal(true);
        setModalStage(1); // Ensure we're on the first stage
    };

    return (
        <div className="dropdown-container">
            <>
                <div className={"dropdown-container-section-title"}>
                    Module Type
                </div>
                <select
                    name="moduleType"
                    value={selection.moduleType}
                    onChange={handleChange}
                    className="select-style"
                >
                    <option value="reading_writing">Reading and Writing</option>
                    <option value="math">Math</option>
                </select>
            </>
            <div className={"dropdown-container-section-title"}>Difficulty</div>
            <>
                <select
                    name="difficulty"
                    value={selection.difficulty}
                    onChange={handleChange}
                    className="select-style"
                >
                    <option value="foundations">Foundations</option>
                    <option value="medium">Medium</option>
                    <option value="advanced">Advanced</option>
                </select>
            </>
            <>
                <div className={"dropdown-container-section-title"}>
                    Time Selection
                </div>
                <select
                    name="timeMultiplier"
                    value={selection.timeMultiplier}
                    onChange={handleChange}
                    className="select-style"
                >
                    <option value="1">1x</option>
                    <option value="1.5">1.5x</option>
                    <option value="2">2x</option>
                    <option value="none">Untimed</option>
                </select>
            </>
            <div>
                <button
                    className={`orange-button ${generateAbility ? "" : "disabled"}`}
                    onClick={generateAbility ? onPrepareGenerateModule : null}
                    disabled={!generateAbility}
                >
                    GENERATE
                </button>
            </div>

            <ConfirmModal
                isOpen={showConfirmModal}
                onConfirm={modalStage === 1 ? handleGenerateModule : null}
                onClose={onClosePopup}
                message={
                    modalStage === 1
                        ? "Are you sure you want to generate a new module?"
                        : "Generating new module..."
                }
                confirmMessage={modalStage === 1 ? "Generate Module" : ""}
                declineMessage="Cancel"
                showActions={modalStage === 1}
            />
        </div>
    );
};

export default GenerateModules;
