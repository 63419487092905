// JourneyContext.js
import { createContext, useContext, useEffect, useState } from "react";
import GenericAsync from "../backend/GenericAsync";
import { useSettings } from "../auth/SettingsContext";
import { useWebhookPoller } from "../backend/useWebhookPoller";

const JourneyContext = createContext(null);

export const JourneyProvider = ({ children }) => {
    const { settings, isModalOpen, settingsSaveAbility } = useSettings();
    const [journey, setJourney] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [generateAbility, setGenerateAbility] = useState(true);
    const [journeyLoading, setJourneyLoading] = useState(false);
    const { watchJob } = useWebhookPoller(`${backendUrl}/api/get-job-status`);
    const maxNewSteps = 10;
    const [activeJobs, setActiveJobs] = useState(new Map());

    const updateGenerateAbility = () => {
        if (journey) {
            const unfinishedCount = journey.filter(
                (step) => step.status !== "complete",
            ).length;
            setGenerateAbility(unfinishedCount <= maxNewSteps);
        }
    };

    const getJourney = async () => {
        const getUserTimezone = () =>
            Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timezone = getUserTimezone();
        setJourneyLoading(true);
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/login-workflow`,
                dataToSend: {
                    userEmail: settings.userEmail,
                    timezone: timezone,
                },
            });
            setJourney(response);
        } catch (error) {
            console.log(error.message);
        }
        setJourneyLoading(false);
    };

    const startWatchingJob = (jobId, step, jobType) => {
        if (step) {
            // Add the step to active jobs with its type
            setActiveJobs((prev) => {
                const newMap = new Map(prev);
                newMap.set(step, jobType);
                return newMap;
            });

            // console.log(jobId, step, jobType)

            return watchJob(jobId, (jobData) => {
                if (jobData.status === "finished") {
                    console.log(jobId, step, jobType);
                    if (
                        jobType === "compute-step-results" &&
                        jobData?.result?.journey_df
                    ) {
                        setJourney(jobData.result.journey_df);
                    } else if (
                        jobType === "generate-step-summary" &&
                        jobData?.result?.journey_df
                    ) {
                        setJourney(jobData.result.journey_df);
                    }

                    // Remove step from active jobs
                    setActiveJobs((prev) => {
                        const newMap = new Map(prev);
                        newMap.delete(step);
                        return newMap;
                    });
                }
            });
        }
    };

    const isStepComputeProcessing = (step) => {
        return (
            activeJobs.has(step) &&
            activeJobs.get(step) === "compute-step-results"
        );
    };

    const isStepSummaryProcessing = (step) => {
        return (
            activeJobs.has(step) &&
            activeJobs.get(step) === "generate-step-summary"
        );
    };

    useEffect(() => {
        if (isModalOpen) return;

        if (settings?.userEmail && settingsSaveAbility && !isModalOpen) {
            getJourney();
        }
    }, [settings?.userEmail, settingsSaveAbility, isModalOpen, refreshFlag]);

    useEffect(() => {
        if (settings?.userEmail && journey) {
            updateGenerateAbility();
        }
    }, [journey]);

    const refreshJourney = () => {
        setRefreshFlag((prevFlag) => !prevFlag);
    };

    // You might also want to add a useEffect to log activeJobs changes
    // useEffect(() => {
    //     console.log("activeJobs updated:", Array.from(activeJobs));
    // }, [activeJobs]);

    return (
        <JourneyContext.Provider
            value={{
                journey,
                setJourney,
                refreshJourney,
                generateAbility,
                journeyLoading,
                startWatchingJob,
                isStepComputeProcessing,
                isStepSummaryProcessing,
            }}
        >
            {children}
        </JourneyContext.Provider>
    );
};

export const useJourney = () => useContext(JourneyContext);
