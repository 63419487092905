import React, { useState } from "react";

const PayoutsTable = ({ records }) => {
    // Change from an object to a single row index
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);

    // Toggle row expansion - only one row can be expanded at a time
    const toggleRow = (index) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    // Format date from Unix timestamp
    const formatDate = (timestamp) => {
        if (!timestamp || timestamp === "none") return "—";
        return new Date(timestamp * 1000).toLocaleDateString();
    };

    // Format amount from cents to dollars
    const formatAmount = (cents) => {
        if (!cents && cents !== 0) return "N/A";
        // Convert cents to dollars and format with commas and 2 decimal places
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
        }).format(cents / 100);
    };

    // Get status display text and class
    const getStatusInfo = (status) => {
        if (!status) return { text: "Pending", className: "status-pending" };

        switch (status) {
            case "paid":
                return { text: "Paid", className: "status-paid" };
            case "in_transit":
                return { text: "In Transit", className: "status-in-transit" };
            case "pre_payout":
                return { text: "Pre-Payout", className: "status-pre-payout" };
            default:
                return { text: status, className: "status-default" };
        }
    };

    return (
        <div className="payouts-table-container">
            {records && records.length > 0 ? (
                <div className="payouts-table">
                    <div className="payouts-table-header">
                        <div className="header-cell">Expected Arrival</div>
                        <div className="header-cell">Amount</div>
                        <div className="header-cell">Status</div>
                    </div>

                    {records.map((record, index) => {
                        const isExpanded = expandedRowIndex === index;
                        // const periodText = `${formatDate(record.start_date)} - ${formatDate(record.end_date)}`;

                        // Filter out null values from transfers array before calculating
                        const validTransfers = record.transfers
                            ? record.transfers.filter(
                                  (transfer) => transfer !== null,
                              )
                            : [];
                        const totalTransfersAmount = validTransfers.reduce(
                            (sum, transfer) => sum + (transfer?.amount || 0),
                            0,
                        );

                        const amount = record.payout
                            ? record.payout.amount
                            : totalTransfersAmount;
                        const status = record.payout
                            ? getStatusInfo(record.payout.status)
                            : getStatusInfo(null);
                        const arrivalDate = record.payout
                            ? formatDate(record.payout.arrival_date)
                            : "Not scheduled";

                        return (
                            <div key={index} className="payout-row-container">
                                <div
                                    className={`payout-row ${isExpanded ? "expanded" : ""}`}
                                >
                                    <div
                                        className="payout-cell"
                                        onClick={() => toggleRow(index)}
                                    >
                                        {arrivalDate}
                                    </div>
                                    <div
                                        className="payout-cell"
                                        onClick={() => toggleRow(index)}
                                    >
                                        {formatAmount(amount)}
                                    </div>
                                    <div
                                        className={`payout-cell status ${status.className}`}
                                        onClick={() => toggleRow(index)}
                                    >
                                        {status.text}
                                    </div>
                                    <div
                                        className="expand-icon"
                                        onClick={() => toggleRow(index)}
                                    ></div>
                                </div>

                                {isExpanded && (
                                    <div className="transfers-container">
                                        <div className="transfers-list">
                                            <div className="transfer-header">
                                                <div className="transfer-header-cell">
                                                    Date
                                                </div>
                                                <div className="transfer-header-cell">
                                                    Amount
                                                </div>
                                                <div className="transfer-header-cell">
                                                    Description
                                                </div>
                                            </div>
                                            {validTransfers.map(
                                                (transfer, transferIndex) => (
                                                    <div
                                                        key={transferIndex}
                                                        className="transfer-row"
                                                    >
                                                        <div className="transfer-cell">
                                                            {formatDate(
                                                                transfer.created_date,
                                                            )}
                                                        </div>
                                                        <div className="transfer-cell">
                                                            {formatAmount(
                                                                transfer.amount,
                                                            )}
                                                        </div>
                                                        <div className="transfer-cell">
                                                            {transfer.description ||
                                                                "No description"}
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <p>No payout records found for the selected date range.</p>
            )}
        </div>
    );
};

export default PayoutsTable;
