import React, { createContext, useState, useContext, useEffect } from "react";
import GenericAsync from "../backend/GenericAsync";
import { useAuth } from "./AuthContext";

export const TutoringContext = createContext();

export const TutoringProvider = ({ children }) => {
    const { authEmail, authInfo } = useAuth();
    const [userInfo, setUserInfo] = useState({});
    const [userArray, setUserArray] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const fetchUserRelations = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/tutoring-api/retrieve-users`,
                dataToSend: {
                    userEmail: authEmail,
                },
            });
            const users = response.users;
            if (users.length > 0) {
                setUserArray(users);
                setUserInfo(users[0]);
                setActiveIndex(0); // Set initial active index
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    // Load initial user data and relations
    useEffect(() => {
        if (authEmail && authInfo) {
            fetchUserRelations();
        }
    }, [authEmail, authInfo]);

    // Update tutorInfo when activeIndex changes
    const selectUser = (index) => {
        if (index >= 0 && index < userArray.length) {
            setActiveIndex(index);
            setUserInfo(userArray[index]);
        }
    };

    return (
        <TutoringContext.Provider
            value={{
                userInfo,
                setUserInfo,
                userArray,
                activeIndex,
                selectUser,
            }}
        >
            {children}
        </TutoringContext.Provider>
    );
};

export const useTutoringUser = () => {
    const context = useContext(TutoringContext);
    if (context === undefined) {
        throw new Error("useTutoring must be used within a TutoringProvider");
    }
    return context;
};
