import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/shared/home/HomePage";
import ProblemPage from "./components/shared/problem_console/ProblemPage";
import Practice from "./components/shared/practice/Practice";
import { UserProvider } from "./components/shared/auth/UserContext"; // adjust the path as necessary
import { JourneyProvider } from "./components/shared/common/JourneyContext";
import { SettingsProvider } from "./components/shared/auth/SettingsContext";

import Settings from "./components/shared/settings/Settings";
import ErrorPage from "./components/shared/errors/ErrorPage";
import ErrorBoundary from "./components/shared/errors/ErrorBoundary";
import { ErrorProvider } from "./components/shared/errors/ErrorContext";
import AdminPage from "./components/shared/admin/AdminPage";
import { useMetaPixel } from "./components/shared/advertising/MetaPixel";
import { useTikTokPixel } from "./components/shared/advertising/TikTokPixel";
import LessonPage from "./components/shared/problem_console/LessonPage"; // Adjust the import path as needed

function AppRoutes() {
    const practiceRoutesConfig = [
        { path: "/practice/tests", stepType: "tests" },
        { path: "/practice/modules", stepType: "modules" },
        { path: "/practice/problem-sets", stepType: "problem-sets" },
        { path: "/practice/lessons", stepType: "lessons" },
    ];

    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            {practiceRoutesConfig.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={<Practice stepType={route.stepType} />}
                />
            ))}
            <Route path="/practice">
                <Route path="lessons/:lessonId" element={<LessonPage />} />
                <Route path=":stepType/:testId" element={<ProblemPage />} />
            </Route>
            <Route path="/settings" element={<Settings />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/admin" element={<AdminPage />} />
        </Routes>
    );
}

function App() {
    useMetaPixel();
    useTikTokPixel();

    return (
        <ErrorProvider>
            <ErrorBoundary>
                <UserProvider>
                    <SettingsProvider>
                        <JourneyProvider>
                            <AppRoutes />
                        </JourneyProvider>
                    </SettingsProvider>
                </UserProvider>
            </ErrorBoundary>
        </ErrorProvider>
    );
}

export default App;
