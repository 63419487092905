import GenericAsync from "../backend/GenericAsync";

// Simple global store for user email
export const userEmailStore = { email: null };

const formatSlackMessage = (error, source = "Unknown Source") => {
    // Get user and environment info
    const url = window.location.href;
    const timestamp = new Date().toISOString();

    // Get the full error stack from error.error if available
    let fullErrorDetails = "";

    if (error?.error) {
        if (typeof error.error === "string") {
            fullErrorDetails = error.error;
        } else if (typeof error.error === "object") {
            try {
                // Try to get the stack directly
                if (error.error.stack) {
                    fullErrorDetails = error.error.stack;
                } else {
                    // Create a replacer function to handle circular references
                    const getCircularReplacer = () => {
                        const seen = new WeakSet();
                        return (key, value) => {
                            if (typeof value === "object" && value !== null) {
                                if (seen.has(value)) {
                                    return "[Circular Reference]";
                                }
                                seen.add(value);
                            }
                            return value;
                        };
                    };

                    fullErrorDetails = JSON.stringify(
                        error.error,
                        getCircularReplacer(),
                        2,
                    );
                }
            } catch (e) {
                fullErrorDetails =
                    "Could not stringify error.error: " + e.message;
            }
        }
    }

    // Add user email if available
    const userSection = userEmailStore.email
        ? `\n\n*User:* ${userEmailStore.email}`
        : "";

    return {
        text:
            `🚨 Frontend Error from ${source}` +
            `\n\n*URL:* ${url}` +
            `\n\n*Time:* ${timestamp}` +
            userSection +
            `\n\n*Error:* ${error?.message}` +
            (fullErrorDetails
                ? `\n\n*Full Error Details:*\n\`\`\`${fullErrorDetails.substring(0, 2000)}\`\`\``
                : ""),
    };
};

export const sendErrorToSlack = async (error, source = "Unknown Source") => {
    try {
        const formattedError = formatSlackMessage(error, source);

        const response = await GenericAsync({
            backendUrl: `${process.env.REACT_APP_BACKEND_URL}/api/handle-frontend-error`,
            dataToSend: {
                error: formattedError,
            },
        });

        return response;
    } catch (reportingError) {
        // Don't throw, just log the error to avoid infinite error loops
        console.error("Error sending to backend:", reportingError);
        return null;
    }
};

export default {
    sendErrorToSlack,
    userEmailStore,
};
