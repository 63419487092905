/**
 * Utility functions for domain and route checking
 */

// Helper function to check if we're on localhost (with or without port)
const isLocalhost = () =>
    window.location.hostname === "localhost" ||
    window.location.hostname.startsWith("localhost:") ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname.startsWith("127.0.0.1:");

// Helper function to check if we're on dev environment
const isDevEnvironment = () =>
    window.location.hostname === "dev.sat.aliaeducation.com" ||
    window.location.hostname === "flamingo-together-fawn.ngrok-free.app";

// Check if we're on the main website domain
export const isMainDomain = () =>
    window.location.hostname === "aliaeducation.com" ||
    window.location.hostname === "www.aliaeducation.com" ||
    window.location.pathname === "/how-it-works" ||
    window.location.pathname === "/pricing" ||
    window.location.pathname === "/apply" ||
    window.location.pathname === "/apply-confirmation" ||
    window.location.pathname === "/checkout" ||
    window.location.pathname === "/signup" ||
    window.location.pathname === "/new-student" ||
    window.location.pathname === "/terms-and-conditions" ||
    window.location.pathname === "/privacy-policy" ||
    ((isLocalhost() || isDevEnvironment()) &&
        window.location.pathname === "/blog");

// Check if we're on the generative domain
export const isGenerativeDomain = () =>
    window.location.hostname === "generative.aliaeducation.com" ||
    ((isLocalhost() || isDevEnvironment()) &&
        (window.location.pathname === "/generative" ||
            window.location.pathname === "/problem-review"));

// Check if we're on the tutoring domain
export const isTutoringDomain = () =>
    window.location.hostname === "tutoring.aliaeducation.com" ||
    ((isLocalhost() || isDevEnvironment()) &&
        window.location.pathname.startsWith("/tutoring"));

// Check if we're on the admin domain
export const isAdminDomain = () =>
    window.location.hostname === "admin.aliaeducation.com" ||
    ((isLocalhost() || isDevEnvironment()) &&
        window.location.pathname.startsWith("/admin"));

// Get base path for a specific domain type
export const getBasePath = (domainType) => {
    const isLocalOrDev = isLocalhost() || isDevEnvironment();

    switch (domainType) {
        case "tutoring":
            return isLocalOrDev ? "/tutoring" : "";
        case "admin":
            return isLocalOrDev ? "/admin" : "";
        case "generative":
            return isLocalOrDev ? "/generative" : "";
        default:
            return "";
    }
};
