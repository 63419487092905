// src/components/MathJaxWrapper.js
import React, { useEffect, useRef } from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";

const MathJaxWrapper = ({ children }) => {
    const mathJaxConfig = {
        loader: { load: ["input/tex", "output/svg"] },
        tex: {
            packages: { "[+]": ["base", "ams", "newcommand", "autoload"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"],
            ],
            displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"],
            ],
            processEscapes: true,
            processEnvironments: true,
            macros: {
                degree: "{\\large ^\\circ}",
            },
        },
        svg: {
            fontCache: "global",
        },
        startup: {
            typeset: true,
        },
    };

    // Track if we've already scheduled a typeset
    const typesetScheduledRef = useRef(false);

    // Use requestAnimationFrame to avoid forced reflows
    const scheduleTypeset = () => {
        if (typesetScheduledRef.current) return;

        typesetScheduledRef.current = true;

        requestAnimationFrame(() => {
            if (window.MathJax) {
                try {
                    // Try the MathJax v3 API
                    if (typeof window.MathJax.typesetPromise === "function") {
                        window.MathJax.typesetPromise();
                    }
                    // Try MathJax v3 alternate API
                    else if (typeof window.MathJax.typeset === "function") {
                        window.MathJax.typeset();
                    }
                } catch (error) {
                    console.error("MathJax typeset error:", error);
                }
            }

            typesetScheduledRef.current = false;
        });
    };

    // Force re-typesetting when children change
    useEffect(() => {
        // Wait for the next frame to avoid forced reflow
        scheduleTypeset();

        // Clean up any scheduled typsetting on unmount
        return () => {
            typesetScheduledRef.current = false;
        };
    }, [children]);

    return (
        <MathJaxContext config={mathJaxConfig} version={3}>
            <MathJax>{children}</MathJax>
        </MathJaxContext>
    );
};

export default MathJaxWrapper;
