import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function StripeCallback() {
    const [status, setStatus] = useState("Processing...");
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        const email = urlParams.get("state");
        console.log("Received code:", code);

        if (code) {
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/tutoring-api/stripe-connect`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ code, email }),
                },
            )
                .then((response) => {
                    console.log("Response:", response); // Debug log
                    return response.json();
                })
                .then((data) => {
                    console.log("Data:", data); // Debug log
                    if (data.success) {
                        setStatus("Successfully connected!");
                        setTimeout(() => navigate("/tutoring/my-info"), 2000);
                    } else {
                        setStatus(`Connection failed: ${data.error}`);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setStatus("Connection failed. Please try again.");
                });
        }
    }, [navigate]);

    return (
        <div className="tutoring-container">
            <h1 className="tutoring-title">Connecting to Stripe</h1>
            <p>{status}</p>
        </div>
    );
}

export default StripeCallback;
