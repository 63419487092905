import React, { useEffect, useState } from "react";
import { Products } from "@stytch/vanilla-js";
import { StytchLogin, StytchPasswordReset } from "@stytch/react";
import { useStytchSession, useStytch } from "@stytch/react";
import { useLocation, Navigate } from "react-router-dom";

// Get the base domain without subdomain
const getBaseDomain = () => {
    // Simply use the current origin (protocol + hostname + port)
    return window.location.origin;
};

const config = {
    products: [Products.passwords, Products.emailMagicLinks],
    emailMagicLinksOptions: {
        loginExpirationMinutes: 60, // Magic link expiration
        loginRedirectURL: `${getBaseDomain()}/authenticate`,
        resetPasswordExpirationMinutes: 60, // Magic link expiration
        resetPasswordRedirectURL: `${getBaseDomain()}/authenticate`,
    },
    passwordOptions: {
        loginExpirationMinutes: 60, // Magic link expiration
        loginRedirectURL: `${getBaseDomain()}/authenticate`,
        resetPasswordExpirationMinutes: 60, // Magic link expiration
        resetPasswordRedirectURL: `${getBaseDomain()}/authenticate`,
        signupRedirectURL: `${getBaseDomain()}/authenticate`,
    },
};

const styles = {
    fontFamily: "DM Sans",
    borderRadius: "6px",
    container: {
        backgroundColor: "white",
    },
    colors: {
        primary: "#003169",
    },
    buttons: {
        primary: {
            backgroundColor: "#003169",
            textColor: "white",
        },
        secondary: {
            backgroundColor: "#003169",
            textColor: "white",
        },
        disabled: {
            backgroundColor: "grey",
            textColor: "white",
        },
    },
    logo: {
        logoImageUrl: "/assets/alia_icon_blue.png",
    },
};

export const Authenticate = () => {
    const { session } = useStytchSession();
    const location = useLocation();
    const stytchClient = useStytch();

    // Parse URL parameters
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const tokenType = params.get("stytch_token_type");

    // Handle token authentication
    useEffect(() => {
        const sessionLength = 24 * 60 * 3; // 3 days

        if (!token || !tokenType) return;

        const authenticateToken = async () => {
            try {
                if (tokenType === "magic_links" || tokenType === "login") {
                    await stytchClient.magicLinks.authenticate(token, {
                        session_duration_minutes: sessionLength,
                    });
                } else if (tokenType === "passwords") {
                    await stytchClient.passwords.authenticate(token, {
                        session_duration_minutes: sessionLength,
                    });
                }
            } catch (error) {
                console.error("Authentication error:", error);
            }
        };

        authenticateToken();
    }, [token, tokenType, stytchClient]);

    // Handle existing session (redirect to app)
    if (session) {
        const redirectPath = localStorage.getItem("preAuthPath") || "/";
        localStorage.removeItem("preAuthPath"); // Clean up
        return <Navigate to={redirectPath} replace />;
    }

    // Show reset password component if that's the token type
    if (tokenType === "reset_password") {
        return <Reset />;
    }

    // Default to showing login form
    return <Login />;
};

export const Login = () => {
    // Store the current path before authentication
    useEffect(() => {
        localStorage.setItem("preAuthPath", window.location.pathname);
    }, []);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                backgroundColor: "#016dea",
            }}
        >
            <StytchLogin config={config} styles={styles} />
        </div>
    );
};

export const Reset = () => {
    const [passwordResetToken, setPasswordResetToken] = useState("");

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get("token");
        setPasswordResetToken(token ?? "");
    }, []);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "#016dea",
            }}
        >
            <StytchPasswordReset
                config={config}
                passwordResetToken={passwordResetToken}
                styles={styles}
            />
        </div>
    );
};
