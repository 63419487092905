import React, { useEffect, useState } from "react";

const AuthSuccess = () => {
    const [countdown, setCountdown] = useState(10);

    // Auto-close tab after countdown
    useEffect(() => {
        if (countdown <= 0) {
            window.close();
            return;
        }

        const timer = setTimeout(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearTimeout(timer);
    }, [countdown]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                fontFamily:
                    "DM Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif",
                backgroundColor: "#f6f8fa",
                color: "#24292f",
                padding: "20px",
            }}
        >
            <img
                src="/assets/alia_icon_blue.png"
                alt="Alia Logo"
                style={{
                    width: "60px",
                    marginBottom: "24px",
                }}
            />
            <h1
                style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    marginBottom: "16px",
                }}
            >
                Authentication Successful
            </h1>
            <div
                style={{
                    maxWidth: "460px",
                    textAlign: "center",
                    marginBottom: "24px",
                    fontSize: "16px",
                    color: "#57606a",
                }}
            >
                You have been successfully authenticated. You can return to your
                original tab where you&apos;ll be logged in automatically.
            </div>
            <div
                style={{
                    fontSize: "14px",
                    color: "#57606a",
                    marginBottom: "16px",
                }}
            >
                This tab will close automatically in {countdown} seconds...
            </div>
            <button
                onClick={() => window.close()}
                style={{
                    backgroundColor: "#003169",
                    color: "white",
                    border: "none",
                    padding: "8px 16px",
                    borderRadius: "6px",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "500",
                }}
            >
                Close Now
            </button>
        </div>
    );
};

export default AuthSuccess;
