import React, { useState } from "react";
import "../common/Style.css";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import ExistingContainer from "./existing/ExistingContainer";
import GenerateContainer from "./generate/GenerateContainer";
import { useUser } from "../auth/UserContext";
import formatString from "../utils/TextProcessing";
import { useSettings } from "../auth/SettingsContext";
import Lessons from "./Lessons";

function PracticeProblems({ stepType, activeTab, handleTabChange }) {
    return (
        <>
            <div className={"page-tab-bar"}>
                <div
                    className={`page-tab-bar-text ${activeTab === "EXISTING" ? "selected" : ""}`}
                    // style={{display: "block"}}
                    onClick={() => handleTabChange("EXISTING")}
                >
                    EXISTING
                </div>
                <div className="page-tab-bar-separator">|</div>
                <div
                    className={`page-tab-bar-text ${activeTab === "GENERATE NEW" ? "selected" : ""}`}
                    onClick={() => handleTabChange("GENERATE NEW")}
                    // style={{textAlign: "left"}}
                >
                    GENERATE NEW
                </div>
            </div>
            <>
                {activeTab === "EXISTING" && (
                    <ExistingContainer stepType={stepType} />
                )}
                {activeTab === "GENERATE NEW" && (
                    <GenerateContainer stepType={stepType} />
                )}
            </>
        </>
    );
}

const Practice = ({ stepType }) => {
    const { profile } = useUser();
    const { subscriptionBannerShown } = useSettings();
    const [isBlurred, setIsBlurred] = useState(false);

    const [activeTab, setActiveTab] = useState(`EXISTING`);
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    if (!profile.email) {
        return <div className="loading-container">Loading...</div>;
    }

    return (
        <div className="desktop-homepage">
            <Navbar setIsBlurred={setIsBlurred} />
            <div
                className={`main-content ${isBlurred ? "blur-effect" : ""}`}
                style={
                    subscriptionBannerShown
                        ? { paddingTop: "140px" }
                        : { paddingTop: "100px" }
                }
            >
                <div className="main-body-text">
                    <div className="page-header">
                        {formatString(stepType.replace(/-/g, " "))}
                    </div>
                    {stepType === "lessons" ? (
                        <Lessons />
                    ) : (
                        PracticeProblems({
                            stepType,
                            activeTab,
                            handleTabChange,
                        })
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Practice;
