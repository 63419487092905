import React, { useState, useEffect, useMemo } from "react";
import { useRecordManagement } from "../hooks/useRecordManagement";
import ManageAbstract from "./ManageAbstract";
import {
    emailSelectorRenderer,
    uuidRenderer,
    organizationRenderer,
    paymentSplitRenderer,
    booleanRenderer,
    readOnlyRenderer,
} from "./CustomRenderers";
import { useTutoringUser } from "../../shared/auth/TutoringContext";

const baseColumnConfig = {
    id: {
        width: 150,
        label: "ID",
        field: "id",
    },
    tutor_email: {
        width: 200,
        label: "Tutor Email",
        field: "tutor_email",
        type: "email",
    },
    client_email: {
        width: 200,
        label: "Client Email",
        field: "client_email",
        type: "email",
    },
    // uuid: {
    //     width: 250,
    //     label: "UUID",
    //     field: "uuid",
    // },
    client_rate: {
        width: 150,
        label: "Client Rate",
        field: "client_rate",
        type: "number",
    },
    tutor_rate: {
        width: 150,
        label: "Tutor Rate",
        field: "tutor_rate",
        type: "number",
    },
    organization: {
        width: 150,
        label: "Organization",
        field: "organization",
    },
    description: {
        width: 200,
        label: "Description",
        field: "description",
    },
    is_default: {
        width: 150,
        label: "Default",
        field: "is_default",
    },
    payment_split: {
        width: 350,
        label: "Payment Split",
        field: "payment_split",
    },
};

const newRowTemplate = {
    tutor_email: "",
    client_email: "",
    client_rate: "",
    tutor_rate: "",
    organization: "",
    description: "",
    is_default: true,
    payment_split: {
        organizations: {
            alia: [
                {
                    split_type: "fee",
                    amount_type: "percent",
                    amount: 3,
                },
                {
                    split_type: "profit_split",
                    amount_type: "percent",
                    amount: 100,
                },
            ],
        },
        referrers: {},
    },
    uuid: crypto.randomUUID(), // Generate a new UUID for new relationships
};

function ManageRelationships({ tutoringPlatform = false }) {
    // Add state for tutors and clients
    const [tutors, setTutors] = useState([]);
    const [clients, setClients] = useState([]);
    const { userInfo } = useTutoringUser();

    // Initialize record management hooks
    const tutorManagement = useRecordManagement("tutor");
    const clientManagement = useRecordManagement("client");

    // Function to check if a relationship has this referrer in its payment_split
    // and also matches the referrer's organization
    const hasReferrer = (relationship) => {
        if (
            !relationship.payment_split ||
            !relationship.payment_split.referrers
        ) {
            return false;
        }

        // Check both the referrer email in payment_split and the organization match
        return (
            Object.keys(relationship.payment_split.referrers).includes(
                userInfo?.email,
            ) && relationship.organization === userInfo?.organization
        );
    };

    // Function to obscure email addresses for privacy
    const obscureEmail = (email) => {
        if (!email) return "";

        const atIndex = email.indexOf("@");
        if (atIndex <= 0) return email; // No @ or it's the first character

        const firstChar = email.charAt(0);
        const domain = email.substring(atIndex);

        return `${firstChar}***${domain}`;
    };

    // Custom payment_split renderer for referrers that only shows their own split
    const referrerPaymentSplitRenderer = (rowData) => {
        if (
            !rowData.payment_split ||
            !rowData.payment_split.referrers ||
            !rowData.payment_split.referrers[userInfo?.email]
        ) {
            return <div>No split information</div>;
        }

        const referrerSplit = rowData.payment_split.referrers[userInfo?.email];
        return (
            <div style={{ whiteSpace: "pre-wrap" }}>
                {referrerSplit.map((split, index) => (
                    <div key={index}>
                        {split.split_type}: {split.amount} {split.amount_type}
                    </div>
                ))}
            </div>
        );
    };

    // Custom tutor email renderer that obscures the email for referrers
    const tutorEmailRenderer = (rowData) => {
        return <div>{obscureEmail(rowData.tutor_email)}</div>;
    };

    const columnConfig = useMemo(() => {
        if (!tutoringPlatform) {
            return baseColumnConfig;
        }

        // Create a new object without the tutor_email and client_rate fields
        const filteredConfig = { ...baseColumnConfig };
        delete filteredConfig.organization;
        delete filteredConfig.client_rate;
        delete filteredConfig.is_default;

        if (filteredConfig.tutor_rate) {
            filteredConfig.tutor_rate = {
                ...filteredConfig.tutor_rate,
                label: "Hourly Rate",
            };
        }

        if (userInfo?.role === "tutor") {
            delete filteredConfig.tutor_email;
            delete filteredConfig.payment_split;
        } else if (userInfo?.role === "referrer") {
            // For referrers, keep minimal info
            delete filteredConfig.tutor_rate;
            // Customize the payment_split label
            if (filteredConfig.payment_split) {
                filteredConfig.payment_split = {
                    ...filteredConfig.payment_split,
                    label: "Commission",
                };
            }
        }

        return filteredConfig;
    }, [tutoringPlatform, userInfo?.role]);

    // Load tutors and clients on component mount
    useEffect(() => {
        const loadData = async () => {
            try {
                const [tutorData, clientData] = await Promise.all([
                    tutorManagement.loadRecords(),
                    clientManagement.loadRecords(),
                ]);

                setTutors(tutorData || []);
                setClients(clientData || []);
            } catch (error) {
                console.error("Error loading data:", error);
            }
        };

        loadData();
    }, [userInfo?.role, tutoringPlatform]);

    const customRenderers = {
        uuid: uuidRenderer,
        tutor_email: tutoringPlatform
            ? userInfo?.role === "referrer"
                ? tutorEmailRenderer
                : readOnlyRenderer()
            : emailSelectorRenderer(tutors, false),
        client_email: tutoringPlatform
            ? readOnlyRenderer()
            : emailSelectorRenderer(clients, false),
        organization: organizationRenderer,
        payment_split:
            userInfo?.role === "referrer"
                ? referrerPaymentSplitRenderer
                : paymentSplitRenderer,
        is_default: booleanRenderer,
        id: readOnlyRenderer(),
        tutor_rate: tutoringPlatform ? readOnlyRenderer() : null,
    };

    // Determine the filter based on user role
    const getFilterBy = () => {
        if (!tutoringPlatform) {
            return null;
        }

        if (userInfo?.role === "tutor") {
            return {
                tutor_email: userInfo?.email,
                organization: userInfo?.organization,
            };
        } else if (userInfo?.role === "referrer") {
            // For referrers, we'll use a custom filter function in ManageAbstract
            // Return a special filter object that will be handled in a custom way
            return {
                __customFilter: (record) => hasReferrer(record),
            };
        }

        return null;
    };

    return (
        <ManageAbstract
            recordType="relationship"
            title="Relationships"
            columnConfig={columnConfig}
            newRowTemplate={newRowTemplate}
            customRenderers={customRenderers}
            primaryEmailField="tutor_email"
            identifierField="uuid"
            allowDeletion={!tutoringPlatform}
            allowAddition={!tutoringPlatform}
            filterBy={getFilterBy()}
        />
    );
}

export default ManageRelationships;
