import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import ManageTutors from "./dashboard/ManageTutors";
import ManageClients from "./dashboard/ManageClients";
import ManageRelationships from "./dashboard/ManageRelationships";
import ManageSessions from "./dashboard/ManageSessions";
import ManageOrganizations from "./dashboard/ManageOrganizations";
import ManagePayments from "./dashboard/ManagePayments";
import ManageTransfers from "./dashboard/ManageTransfers";
import ManageReferrers from "./dashboard/ManageReferrers";
import styles from "./css/AdminStyles.module.css";
import { useTutoringUser } from "../shared/auth/TutoringContext";
import { useAuth } from "../shared/auth/AuthContext";
import { useState, useEffect } from "react";

function Dashboard({ tutoringPlatform = false }) {
    const { userInfo } = useTutoringUser();
    const { authInfo } = useAuth();

    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const role = tutoringPlatform ? userInfo?.role : authInfo?.role;

        setUserRole(role || null);
    }, [authInfo, userInfo, tutoringPlatform]);

    return (
        <div className={styles.adminContainer}>
            <h1 className={styles.adminTitle}>
                {tutoringPlatform ? "Tutoring Dashboard" : "Admin Dashboard"}
            </h1>

            <nav className={styles.adminNav}>
                {(userRole === "admin" || userRole === "tutor") && (
                    <NavLink
                        to="sessions"
                        className={({ isActive }) =>
                            `${styles.adminNavLink} ${isActive ? styles.active : ""}`
                        }
                    >
                        Sessions
                    </NavLink>
                )}
                {userRole === "admin" && (
                    <NavLink
                        to="tutors"
                        className={({ isActive }) =>
                            `${styles.adminNavLink} ${isActive ? styles.active : ""}`
                        }
                    >
                        Tutors
                    </NavLink>
                )}
                {(userRole === "admin" || userRole === "tutor") && (
                    <NavLink
                        to="clients"
                        className={({ isActive }) =>
                            `${styles.adminNavLink} ${isActive ? styles.active : ""}`
                        }
                    >
                        Clients
                    </NavLink>
                )}
                {(userRole === "admin" ||
                    userRole === "tutor" ||
                    userRole === "referrer") && (
                    <NavLink
                        to="relationships"
                        className={({ isActive }) =>
                            `${styles.adminNavLink} ${isActive ? styles.active : ""}`
                        }
                    >
                        Relationships
                    </NavLink>
                )}
                {userRole === "admin" && (
                    <>
                        <NavLink
                            to="referrers"
                            className={({ isActive }) =>
                                `${styles.adminNavLink} ${isActive ? styles.active : ""}`
                            }
                        >
                            Referrers
                        </NavLink>
                        <NavLink
                            to="organizations"
                            className={({ isActive }) =>
                                `${styles.adminNavLink} ${isActive ? styles.active : ""}`
                            }
                        >
                            Organizations
                        </NavLink>
                        <NavLink
                            to="payments"
                            className={({ isActive }) =>
                                `${styles.adminNavLink} ${isActive ? styles.active : ""}`
                            }
                        >
                            Payments
                        </NavLink>
                        <NavLink
                            to="transfers"
                            className={({ isActive }) =>
                                `${styles.adminNavLink} ${isActive ? styles.active : ""}`
                            }
                        >
                            Transfers
                        </NavLink>
                    </>
                )}
            </nav>

            <div className={styles.adminContent}>
                <Routes>
                    <>
                        {userRole === "admin" && (
                            <>
                                <Route
                                    path="tutors"
                                    element={<ManageTutors />}
                                />
                                <Route
                                    path="referrers"
                                    element={<ManageReferrers />}
                                />
                                <Route
                                    path="organizations"
                                    element={<ManageOrganizations />}
                                />
                                <Route
                                    path="payments"
                                    element={<ManagePayments />}
                                />
                                <Route
                                    path="transfers"
                                    element={<ManageTransfers />}
                                />
                            </>
                        )}
                        {(userRole === "admin" || userRole === "tutor") && (
                            <>
                                <Route
                                    path="clients"
                                    element={
                                        <ManageClients
                                            tutoringPlatform={tutoringPlatform}
                                        />
                                    }
                                />
                                <Route
                                    path="sessions"
                                    element={
                                        <ManageSessions
                                            tutoringPlatform={tutoringPlatform}
                                        />
                                    }
                                />
                            </>
                        )}
                        <Route
                            path="relationships"
                            element={
                                <ManageRelationships
                                    tutoringPlatform={tutoringPlatform}
                                />
                            }
                        />
                    </>
                </Routes>
            </div>
        </div>
    );
}

export default Dashboard;
