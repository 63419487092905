import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
// import GenerativeHome from "./components/generative/GenerativeHome";
import ProblemParent from "./components/generative/problem_console/ProblemParent";
import { updateIntercomVisibility } from "./components/shared/common/intercom/IntercomUtils";
import GenerativeHomeConstruction from "./components/generative/GenerativeHomeConstruction";

function Generative() {
    const location = useLocation();

    useEffect(() => {
        updateIntercomVisibility(false);
    }, [location]);

    return (
        <Routes>
            <Route path="/" element={<GenerativeHomeConstruction />} />
            <Route
                path="/generative"
                element={<GenerativeHomeConstruction />}
            />
            <Route path="/problem-review" element={<ProblemParent />} />
        </Routes>
    );
}

export default Generative;
