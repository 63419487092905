import GenericAsync from "../../shared/backend/GenericAsync";

export const useRecordManagement = (recordType) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const loadRecords = () => {
        return GenericAsync({
            backendUrl: `${backendUrl}/tutoring-api/records/${recordType}`,
            dataToSend: {},
            jsonify: true,
            method: "GET",
        });
    };

    const loadRecord = (recordType, identifier) => {
        return GenericAsync({
            backendUrl: `${backendUrl}/tutoring-api/records/${recordType}/${identifier}`,
            jsonify: true,
            method: "GET",
        });
    };

    const saveRecord = async (recordData, identifier) => {
        if (!identifier) {
            throw new Error(
                `Email/UUID is required for saving record ${identifier}`,
            );
        }

        return GenericAsync({
            backendUrl: `${backendUrl}/tutoring-api/records/${recordType}/${identifier}`,
            dataToSend: recordData,
            jsonify: true,
            method: "POST",
        });
    };

    return {
        saveRecord,
        loadRecord,
        loadRecords,
    };
};
