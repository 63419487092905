import { useState, useCallback } from "react";

export const useResizableColumns = (columnConfig) => {
    // Initialize column widths from config
    const [columnWidths, setColumnWidths] = useState(() => {
        const initialWidths = {};
        Object.keys(columnConfig).forEach((key) => {
            initialWidths[key] = columnConfig[key].width || 150; // Default width if not specified
        });
        initialWidths.actions = 150; // Default width for actions column
        return initialWidths;
    });

    const startResizing = useCallback(
        (column, startX, startWidth, nextColumn, nextStartWidth) => {
            const handleMouseMove = (e) => {
                const diff = e.clientX - startX;

                // Ensure minimum width of 50px for both columns
                const newWidth = Math.max(50, startWidth + diff);
                const newNextWidth = Math.max(50, nextStartWidth - diff);

                setColumnWidths((prev) => ({
                    ...prev,
                    [column]: newWidth,
                    ...(nextColumn ? { [nextColumn]: newNextWidth } : {}),
                }));
            };

            const handleMouseUp = () => {
                document.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("mouseup", handleMouseUp);
            };

            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        },
        [],
    );

    return { columnWidths, startResizing };
};
