import React from "react";

const NotFound = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                fontFamily:
                    "DM Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif",
                backgroundColor: "#f6f8fa",
                color: "#24292f",
                padding: "20px",
            }}
        >
            <img
                src="/assets/alia_icon_blue.png"
                alt="Alia Logo"
                style={{
                    width: "60px",
                    marginBottom: "24px",
                }}
            />
            <h1
                style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    marginBottom: "16px",
                }}
            >
                Page Not Found
            </h1>
            <div
                style={{
                    maxWidth: "460px",
                    textAlign: "center",
                    marginBottom: "24px",
                    fontSize: "16px",
                    color: "#57606a",
                }}
            >
                Sorry, we couldn&apos;t find the page you&apos;re looking for.
                It may not exist, or you may not have permission to view it.
            </div>
        </div>
    );
};

export default NotFound;
