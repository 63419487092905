import React from "react";

import "../tutoring/css/TutoringStyles.css";

function Home() {
    return (
        <div className="tutoring-container">
            <h1 className="tutoring-title">
                Welcome to the Alia Admin Platform!
            </h1>
            <p>Select an option from the menu to get started.</p>
        </div>
    );
}

export default Home;
