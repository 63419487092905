import React, { useMemo, useState, useEffect } from "react";
import { useRecordManagement } from "../hooks/useRecordManagement";
import ManageAbstract from "./ManageAbstract";
import { useTutoringUser } from "../../shared/auth/TutoringContext";

import {
    reportingEmailsRenderer,
    organizationRenderer,
    readOnlyRenderer,
} from "./CustomRenderers";

const baseColumnConfig = {
    id: {
        width: 50,
        label: "ID",
        field: "id",
    },
    first_name: {
        width: 150,
        label: "First Name",
        field: "first_name",
    },
    last_name: {
        width: 150,
        label: "Last Name",
        field: "last_name",
    },
    email: {
        width: 200,
        label: "Email",
        type: "email",
    },
    reporting_emails: {
        width: 200,
        label: "Reporting Emails",
        field: "reporting_emails",
    },
    organization: {
        width: 150,
        label: "Organization",
        field: "organization",
    },
    stripe_id: {
        width: 150,
        label: "Stripe ID",
        field: "stripe_id",
    },
};

const newRowTemplate = {
    email: "",
    uuid: crypto.randomUUID(),
    first_name: "",
    last_name: "",
    reporting_emails: [],
    organization: "",
    stripe_user_id: "",
};

function ManageClients({ tutoringPlatform = false }) {
    // Create a filtered columnConfig based on tutoringPlatform prop
    const [tutoringClients, setTutoringClients] = useState([]);
    const relationshipManagement = useRecordManagement("relationship");
    const { userInfo } = useTutoringUser();

    const columnConfig = useMemo(() => {
        if (!tutoringPlatform) {
            return baseColumnConfig;
        }

        // Create a new object without the tutor_email and client_rate fields
        const filteredConfig = { ...baseColumnConfig };
        delete filteredConfig.stripe_id;
        delete filteredConfig.organization;

        return filteredConfig;
    }, [tutoringPlatform]);

    useEffect(() => {
        const loadData = async () => {
            try {
                let [relationshipData] = await Promise.all([
                    relationshipManagement.loadRecords(),
                ]);

                relationshipData = relationshipData.filter(
                    (relationship) =>
                        relationship.tutor_email === userInfo?.email &&
                        relationship.organization === userInfo?.organization,
                );
                setTutoringClients(
                    relationshipData.map(
                        (relationship) => relationship.client_email,
                    ) || [],
                );
            } catch (error) {
                console.error("Error loading data:", error);
            }
        };
        if (tutoringPlatform) {
            loadData();
        }
    }, []);

    const customRenderers = {
        reporting_emails: reportingEmailsRenderer,
        organization: organizationRenderer,
        id: readOnlyRenderer(),
        first_name: tutoringPlatform ? readOnlyRenderer() : null,
        last_name: tutoringPlatform ? readOnlyRenderer() : null,
        email: tutoringPlatform ? readOnlyRenderer() : null,
    };

    return (
        <ManageAbstract
            recordType="client"
            title="Clients"
            columnConfig={columnConfig}
            newRowTemplate={newRowTemplate}
            customRenderers={customRenderers}
            primaryEmailField="email"
            identifierField="uuid"
            allowDeletion={!tutoringPlatform}
            allowAddition={!tutoringPlatform}
            filterBy={tutoringPlatform ? { email: tutoringClients } : null}
        />
    );
}

export default ManageClients;
