import "../css/Home.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const ApplyToday = () => {
    const navigate = useNavigate();

    return (
        <div className="as-container" style={{ backgroundColor: "#016dea" }}>
            <div className="as-content">
                <div className="as-title" style={{ paddingBottom: "40px" }}>
                    Apply Today to Become an Alia Learner
                </div>
                <div
                    className="as-text"
                    style={{
                        width: "95%",
                        textAlign: "center",
                        paddingLeft: "0px",
                        paddingBottom: "50px",
                        maxWidth: "800px",
                    }}
                >
                    Ready to transform your SAT prep experience? Start your
                    journey to a higher score now.
                </div>
                <button
                    className="apply-button"
                    style={{
                        color: "#003169",
                        backgroundColor: "#FFAB24",
                    }}
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default ApplyToday;
