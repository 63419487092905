import Navbar from "../common/Navbar";
import React, { useEffect, useState } from "react";
import Footer from "../common/Footer";
import { useSettings } from "../auth/SettingsContext";
import UpdateUserComponent from "./UpdateUserComponent";
import GenericAsync from "../backend/GenericAsync";
import GenericModal from "../../generative/modals/GenericModal";

const AdminPage = () => {
    const [selection, setSelection] = useState({
        action: "",
        org_action: "",
        assignment_class: "reading_writing",
        assignment_difficulty: "foundations",
    });
    const [userInfo, setUserInfo] = useState({});
    const [isBlurred, setIsBlurred] = useState(false);
    const { settings } = useSettings();
    const [allUsers, setAllUsers] = useState(null);
    const [allOrgs, setAllOrgs] = useState(null);
    const [selectedOrg, setSelectedOrg] = useState("");
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedDays, setSelectedDays] = useState("7"); // Default to 7 days
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSelection({ ...selection, [name]: value });
    };

    const handleSelectUser = (event) => {
        const email = event.target.value;
        setSelectedUser(email);
        getUserInfo(email);
    };

    const handleSelectOrg = (event) => {
        const org = event.target.value;
        setSelectedOrg(org);
    };

    const getUserInfo = async (userEmail) => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/get-user-info`,
                dataToSend: {
                    userEmail: userEmail,
                },
            });
            setUserInfo(response);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getAllUsers = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/get-all-users`,
                dataToSend: {
                    userEmail: settings.userEmail,
                },
            });
            setAllUsers(response.users);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getAllOrgs = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/get-all-organizations`,
                dataToSend: {
                    userEmail: settings.userEmail,
                },
            });
            if (response.org_files) {
                // Parse the org names from S3 URIs
                const formattedOrgs = response.org_files.map((uri) => {
                    // Get the basename (filename) from the URI
                    const basename = uri.split("/").pop();
                    // Remove .csv extension and convert hyphens to spaces
                    return basename.replace(".csv", "").replace(/-/g, " ");
                });
                setAllOrgs(formattedOrgs);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const renderUserOptions = (choices) => {
        const defaultOption = (
            <option key="default" value="">
                Select an option
            </option>
        );

        if (choices !== null && Array.isArray(choices)) {
            return [
                defaultOption,
                ...choices.map((choice) => (
                    <option key={choice} value={choice}>
                        {choice}
                    </option>
                )),
            ];
        }

        return defaultOption;
    };

    const handleDaySelect = (event) => {
        setSelectedDays(event.target.value);
    };

    const TIME_RANGES = [
        { value: "7", label: "Last 7 days" },
        { value: "14", label: "Last 14 days" },
        { value: "30", label: "Last 30 days" },
        { value: "90", label: "Last 90 days" },
        { value: "all_time", label: "All time" },
    ];

    useEffect(() => {
        if (selection.action === "edit_user") {
            getAllUsers();
        } else if (
            selection.action === "org_report" ||
            selection.action === "org_assignment"
        ) {
            getAllOrgs();
        }
    }, [selection.action]);

    function returnContent() {
        if (
            !["terence.m.conlon@gmail.com", "nrish07@gmail.com"].includes(
                settings?.userEmail,
            )
        ) {
            return <>You do not have access to this page!</>;
        } else {
            return (
                <>
                    <div className={"select-menu"}>
                        <select
                            name="action"
                            value={selection.action}
                            onChange={handleChange}
                            className="select-style"
                            style={{
                                width: "300px",
                            }}
                        >
                            <option value="" disabled>
                                Select action
                            </option>
                            <option value="add_user">Add new user</option>
                            <option value="edit_user">
                                Edit existing user
                            </option>
                            <option value="org_report">
                                Generate organization report
                            </option>
                            <option value="org_assignment">
                                Assign module to organization
                            </option>
                        </select>
                    </div>
                    {selection.action === "edit_user" && allUsers && (
                        <div className={"select-menu"}>
                            <select
                                onChange={handleSelectUser}
                                value={selectedUser}
                                className="select-style"
                                style={{
                                    width: "300px",
                                }}
                            >
                                {renderUserOptions(allUsers)}
                            </select>
                        </div>
                    )}
                    {(selection.action === "edit_user" ||
                        selection.action === "add_user") && (
                        <div
                            className="settings-main-text"
                            style={{ paddingBottom: "60px" }}
                        >
                            {userInfo && (
                                <UpdateUserComponent
                                    action={selection.action}
                                    userInfo={userInfo}
                                    setUserInfo={setUserInfo}
                                />
                            )}
                        </div>
                    )}

                    {(selection.action === "org_report" ||
                        selection.action === "org_assignment") &&
                        allOrgs && (
                            <div className={"select-menu"}>
                                <select
                                    onChange={handleSelectOrg}
                                    value={selectedOrg}
                                    className="select-style"
                                    style={{
                                        width: "300px",
                                    }}
                                >
                                    {renderUserOptions(allOrgs)}
                                </select>
                            </div>
                        )}
                    {selection.action === "org_report" && selectedOrg && (
                        <div className={"select-menu"}>
                            <select
                                name="org_action"
                                value={selection.org_action}
                                onChange={handleChange}
                                className="select-style"
                                style={{
                                    width: "300px",
                                }}
                            >
                                <option value="" disabled>
                                    Select action
                                </option>
                                <option value="usage">
                                    Generate usage report
                                </option>
                            </select>
                        </div>
                    )}
                    {selection.action === "org_assignment" && selectedOrg && (
                        <>
                            <div className={"select-menu"}>
                                <select
                                    name="assignment_class"
                                    value={selection.assignment_class}
                                    onChange={handleChange}
                                    className="select-style"
                                    style={{
                                        width: "300px",
                                    }}
                                >
                                    <option value="reading_writing">
                                        Reading and Writing
                                    </option>
                                    <option value="math">Math</option>
                                </select>
                            </div>
                            <div className={"select-menu"}>
                                <select
                                    name="assignment_difficulty"
                                    value={selection.assignment_difficulty}
                                    onChange={handleChange}
                                    className="select-style"
                                    style={{
                                        width: "300px",
                                    }}
                                >
                                    <option value="foundations">
                                        Foundations
                                    </option>
                                    <option value="medium">Medium</option>
                                    <option value="advanced">Advanced</option>
                                </select>
                            </div>
                            <button
                                className="orange-button"
                                style={{ marginTop: "20px" }}
                                onClick={() =>
                                    handleAssignModule(selectedOrg, selection)
                                }
                            >
                                Assign module
                            </button>
                        </>
                    )}
                    {selectedOrg && selection.org_action === "usage" && (
                        <div className={"select-menu"}>
                            <select
                                name="time_range"
                                value={selectedDays}
                                onChange={handleDaySelect}
                                className="select-style"
                                style={{ width: "300px" }}
                            >
                                {TIME_RANGES.map(({ value, label }) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {selectedOrg &&
                        selection.org_action === "usage" &&
                        selectedDays && (
                            <button
                                className="orange-button"
                                style={{ marginTop: "20px" }}
                                onClick={() =>
                                    handleGenerateReport(
                                        selectedOrg,
                                        selectedDays,
                                    )
                                }
                            >
                                Generate Report
                            </button>
                        )}
                </>
            );
        }
    }

    const handleGenerateReport = async (org, days) => {
        setIsModalOpen(true);
        setModalMessage("Generating report...");

        try {
            // Use fetch directly instead of GenericAsync for file downloads
            const response = await fetch(
                `${backendUrl}/api/generate-org-report`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userEmail: settings.userEmail,
                        organization: org,
                        days: days,
                    }),
                },
            );

            if (!response.ok) throw new Error("Network response was not ok");

            // Get the filename from the Content-Disposition header
            const contentDisposition = response.headers.get(
                "Content-Disposition",
            );
            const filename = contentDisposition
                ? contentDisposition.split("filename=")[1].replace(/"/g, "")
                : "user_activity.xlsx";

            // Convert response to blob and download
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            setModalMessage("Report generated successfully!");
        } catch (error) {
            console.error("Failed to generate report:", error);
            setModalMessage("Failed to generate report. Please try again.");
        }
    };

    const handleAssignModule = async (org, selection) => {
        setIsModalOpen(true);
        setModalMessage("Assigning modules...");
        try {
            await GenericAsync({
                backendUrl: `${backendUrl}/api/assign-module`,
                dataToSend: {
                    organization: org,
                    assignment_class: selection.assignment_class,
                    assignment_difficulty: selection.assignment_difficulty,
                },
            });
            setModalMessage("Module assigned successfully!");
        } catch (error) {
            console.error("Failed to assign module:", error);
            setModalMessage("Failed to assign module. Please try again.");
        }
    };

    return (
        <div className="desktop-homepage">
            <Navbar setIsBlurred={setIsBlurred} />
            <div className={`main-content ${isBlurred ? "blur-effect" : ""}`}>
                <div className="main-body-text">
                    <div
                        className="welcome-banner"
                        style={{ marginBottom: "50px" }}
                    >
                        Admin
                    </div>
                    <div className="admin-container">{returnContent()}</div>
                </div>
            </div>
            <Footer />
            <GenericModal
                isOpen={isModalOpen}
                onConfirm={() => setIsModalOpen(false)}
                message={modalMessage}
                confirmMessage="Close"
                showActions={true}
            />
        </div>
    );
};

export default AdminPage;
