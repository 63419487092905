import React, { useState, useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    TimeSeriesScale,
} from "chart.js";
import "chartjs-adapter-date-fns";

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    TimeSeriesScale,
);

const PayoutsSummary = ({ allPayouts, dateRangeStart, dateRangeEnd }) => {
    // State for time range selection
    const [timeRange, setTimeRange] = useState("custom"); // 'all', 'year', 'month', 'week', 'custom'

    // Format amount from cents to dollars
    const formatAmount = (cents) => {
        if (!cents && cents !== 0) return "$0.00";
        // Convert cents to dollars and format with commas and 2 decimal places
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
        }).format(cents / 100);
    };

    // Calculate total lifetime payouts
    const calculateLifetimeTotal = (payouts) => {
        if (!payouts || !Array.isArray(payouts)) return 0;

        return payouts.reduce((total, payout) => {
            if (payout && payout.amount) {
                return total + payout.amount;
            }
            return total;
        }, 0);
    };

    // Calculate payouts within date range
    const calculateDateRangeTotal = (payouts, startDate, endDate) => {
        if (!payouts || !Array.isArray(payouts) || !startDate || !endDate)
            return 0;

        // Convert date strings to timestamps for comparison
        const startTimestamp = new Date(startDate).getTime() / 1000;
        const endTimestamp = new Date(endDate).getTime() / 1000;

        return payouts.reduce((total, payout) => {
            if (
                payout &&
                payout.amount &&
                payout.arrival_date &&
                payout.arrival_date !== "none"
            ) {
                // Check if payout is within date range
                if (
                    payout.arrival_date >= startTimestamp &&
                    payout.arrival_date <= endTimestamp
                ) {
                    return total + payout.amount;
                }
            }
            return total;
        }, 0);
    };

    // Process payout data for chart based on selected time range
    const getChartData = useMemo(() => {
        if (!allPayouts || !Array.isArray(allPayouts)) {
            return { labels: [], data: [], dates: [] };
        }

        // Sort payouts by arrival date
        const sortedPayouts = [...allPayouts]
            .filter(
                (payout) =>
                    payout &&
                    payout.arrival_date &&
                    payout.arrival_date !== "none",
            )
            .sort((a, b) => a.arrival_date - b.arrival_date);

        if (sortedPayouts.length === 0) {
            return { labels: [], data: [], dates: [] };
        }

        // Determine start date based on selected range
        const now = new Date();
        let startDate;

        switch (timeRange) {
            case "week":
                // Start from beginning of current week (Sunday)
                startDate = new Date(now);
                startDate.setDate(now.getDate() - now.getDay());
                startDate.setHours(0, 0, 0, 0);
                break;
            case "month":
                // Start from beginning of current month
                startDate = new Date(now.getFullYear(), now.getMonth(), 1);
                break;
            case "year":
                // Start from beginning of current year
                startDate = new Date(now.getFullYear(), 0, 1);
                break;
            case "custom":
                // Use the date range provided via props
                startDate = new Date(dateRangeStart);
                startDate.setHours(0, 0, 0, 0);
                break;
            case "all":
            default:
                // Use earliest payout date
                startDate = new Date(sortedPayouts[0].arrival_date * 1000);
                break;
        }

        const startTimestamp = Math.floor(startDate.getTime() / 1000);

        // For custom range, we also need an end date
        let endTimestamp;
        if (timeRange === "custom") {
            const endDate = new Date(dateRangeEnd);
            endDate.setHours(23, 59, 59, 999);
            endTimestamp = Math.floor(endDate.getTime() / 1000);
        }

        // Filter payouts based on selected time range
        const rangePayouts = sortedPayouts.filter((payout) => {
            if (timeRange === "custom") {
                return (
                    payout.arrival_date >= startTimestamp &&
                    payout.arrival_date <= endTimestamp
                );
            }
            return payout.arrival_date >= startTimestamp;
        });

        if (rangePayouts.length === 0) {
            return { labels: [], data: [], dates: [] };
        }

        // Calculate running sum and prepare chart data
        let runningSum = 0;
        const data = [];
        const labels = [];
        const dates = [];

        rangePayouts.forEach((payout) => {
            // Create date without time information by setting all time components to 0
            const timestamp = payout.arrival_date * 1000;
            const fullDate = new Date(timestamp);

            // Create a new date with only year/month/day information
            const dateWithoutTime = new Date(
                fullDate.getFullYear(),
                fullDate.getMonth(),
                fullDate.getDate(),
                0,
                0,
                0,
            );

            dates.push(dateWithoutTime);

            // Format the label based on time range
            let label;
            switch (timeRange) {
                case "week":
                case "month":
                case "custom":
                    label = dateWithoutTime.toLocaleDateString(undefined, {
                        month: "short",
                        day: "numeric",
                    });
                    break;
                case "year":
                    label = dateWithoutTime.toLocaleDateString(undefined, {
                        month: "short",
                        year: "numeric",
                    });
                    break;
                case "all":
                default:
                    label = dateWithoutTime.toLocaleDateString(undefined, {
                        year: "numeric",
                        month: "short",
                    });
                    break;
            }

            runningSum += payout.amount;
            labels.push(label);
            data.push(runningSum / 100); // Convert cents to dollars for the chart
        });

        return { labels, data, dates };
    }, [allPayouts, timeRange, dateRangeStart, dateRangeEnd]);

    const lifetimeTotal = calculateLifetimeTotal(allPayouts);
    const dateRangeTotal = calculateDateRangeTotal(
        allPayouts,
        dateRangeStart,
        dateRangeEnd,
    );

    // Chart configuration
    const chartData = useMemo(
        () => ({
            labels: getChartData.dates,
            datasets: [
                {
                    label: "Cumulative Earnings",
                    data: getChartData.data.map((value, index) => ({
                        x: getChartData.dates[index],
                        y: value,
                    })),
                    borderColor: "#003169",
                    backgroundColor: "rgba(0, 49, 105, 0.1)",
                    fill: true,
                    tension: 0.3,
                    pointRadius: 3,
                    pointHoverRadius: 5,
                },
            ],
        }),
        [getChartData],
    );

    const chartOptions = useMemo(
        () => ({
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 300, // Faster animations
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 2,
                            }).format(context.raw.y);
                        },
                        title: function (context) {
                            const date = context[0].raw.x;
                            return date.toLocaleDateString(undefined, {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                            });
                        },
                    },
                },
            },

            scales: {
                x: {
                    type: "time",
                    time: {
                        unit:
                            timeRange === "week"
                                ? "day"
                                : timeRange === "month"
                                  ? "day"
                                  : timeRange === "custom"
                                    ? "day"
                                    : timeRange === "year"
                                      ? "month"
                                      : "month",
                        displayFormats: {
                            day: "MMM d",
                            month: "MMM yyyy",
                        },
                        tooltipFormat: "MMM d, yyyy",
                    },
                    grid: {
                        display: true,
                        color: "rgba(0, 0, 0, 0.05)",
                    },
                    ticks: {
                        maxTicksLimit: timeRange === "custom" ? 10 : 6,
                        maxRotation: 0,
                    },
                },
                y: {
                    beginAtZero: true,
                    grid: {
                        color: "rgba(0, 0, 0, 0.05)",
                    },
                    ticks: {
                        callback: function (value) {
                            return new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                                maximumFractionDigits: 0,
                            }).format(value);
                        },
                    },
                },
            },
        }),
        [timeRange],
    );

    return (
        <div className="payouts-summary">
            <div className="summary-card">
                <div className="summary-row">
                    <div className="summary-label">Lifetime Payouts:</div>
                    <div className="summary-value">
                        {formatAmount(lifetimeTotal)}
                    </div>
                </div>
                <div className="summary-row">
                    <div className="summary-label">
                        Payouts in Selected Date Range:
                    </div>
                    <div className="summary-value">
                        {formatAmount(dateRangeTotal)}
                    </div>
                </div>
            </div>

            <div className="chart-container">
                <h3 className="chart-title">Completed Payouts History</h3>
                <div className="chart-wrapper">
                    {getChartData.data.length > 0 ? (
                        <Line
                            data={chartData}
                            options={chartOptions}
                            height={250}
                        />
                    ) : (
                        <div className="no-chart-data">
                            No payouts completed for the selected time range
                        </div>
                    )}
                </div>
                <div className="time-range-buttons">
                    <button
                        className={`time-range-btn ${timeRange === "custom" ? "active" : ""}`}
                        onClick={() => setTimeRange("custom")}
                    >
                        Specified Dates
                    </button>
                    <button
                        className={`time-range-btn ${timeRange === "week" ? "active" : ""}`}
                        onClick={() => setTimeRange("week")}
                    >
                        Week
                    </button>
                    <button
                        className={`time-range-btn ${timeRange === "month" ? "active" : ""}`}
                        onClick={() => setTimeRange("month")}
                    >
                        Month
                    </button>
                    <button
                        className={`time-range-btn ${timeRange === "year" ? "active" : ""}`}
                        onClick={() => setTimeRange("year")}
                    >
                        Year
                    </button>
                    <button
                        className={`time-range-btn ${timeRange === "all" ? "active" : ""}`}
                        onClick={() => setTimeRange("all")}
                    >
                        All Time
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PayoutsSummary;
