import React, { useState, useEffect } from "react";
import { useRecordManagement } from "../hooks/useRecordManagement";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import styles from "../css/AdminStyles.module.css";
import { useResizableColumns } from "../hooks/useResizableColumns";
import GenericModal from "../../generative/modals/GenericModal";

function ManageAbstract({
    recordType,
    title,
    columnConfig,
    newRowTemplate,
    customRenderers = {},
    primaryEmailField = "email",
    identifierField = "uuid",
    customActions,
    requiredFields = [],
    filterBy = null,
    allowDeletion = true,
    allowAddition = true,
}) {
    const [editingRow, setEditingRow] = useState(null);
    const [newRow, setNewRow] = useState(null);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [filterValue, setFilterValue] = useState("");
    const [saveModal, setSaveModal] = useState({
        isOpen: false,
        message: "",
        isProcessing: false,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [records, setRecords] = useState([]);

    const { loadRecords, saveRecord } = useRecordManagement(recordType);

    const { columnWidths, startResizing } = useResizableColumns(columnConfig);

    // Move fetchRecords outside useEffect so we can reuse it
    const fetchRecords = async () => {
        setIsLoading(true);
        try {
            const data = await loadRecords();

            // Apply filters if provided
            let filteredData = data;

            if (filterBy) {
                filteredData = data.filter((record) => {
                    // Handle custom filter function if provided
                    if (filterBy.__customFilter) {
                        return filterBy.__customFilter(record);
                    }

                    // Check each filter criterion
                    for (const [key, value] of Object.entries(filterBy)) {
                        // Skip the custom filter property
                        if (key === "__customFilter") continue;

                        if (value) {
                            // Handle array values (like lists of emails)
                            if (Array.isArray(value)) {
                                // If the filter value is an array, check if the record's value is in that array
                                if (!value.includes(record[key])) {
                                    return false;
                                }
                            }
                            // Handle normal exact matching
                            else if (record[key] !== value) {
                                return false;
                            }
                        }
                    }
                    return true;
                });
            }

            // Apply text filter if any
            setRecords(
                filteredData?.filter((record) =>
                    record[primaryEmailField]
                        ?.toLowerCase()
                        ?.startsWith(filterValue.toLowerCase()),
                ),
            );
        } catch (error) {
            console.error("Error loading records:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Use it in useEffect as before
    useEffect(() => {
        fetchRecords();
    }, [recordType, filterBy]);

    useEffect(() => {
        // Apply text filter
        let filtered = records;

        if (filterValue) {
            filtered = records.filter((record) =>
                record[primaryEmailField]
                    ?.toLowerCase()
                    ?.startsWith(filterValue.toLowerCase()),
            );
        }

        setFilteredRecords(filtered);
    }, [filterValue, records, primaryEmailField]);

    const handleInputChange = (rowData, field, value) => {
        const updateRow = (prev) => ({
            ...prev,
            [field]: value,
        });

        if (rowData.isNew) {
            setNewRow(updateRow);
        } else {
            setEditingRow(updateRow);
        }
    };

    const handlePhoneChange = (rowData, value, country, e, formattedValue) => {
        const valid = !value || isValidPhoneNumber("+" + value);
        setIsPhoneValid(valid);

        if (rowData.isNew) {
            setNewRow((prev) => ({ ...prev, phone_number: formattedValue }));
        } else {
            setEditingRow((prev) => ({
                ...prev,
                phone_number: formattedValue,
            }));
        }
    };

    const handleSave = async (rowData) => {
        let message = "Saving record...";
        setSaveModal({
            isOpen: true,
            message: message,
            isProcessing: true,
        });

        try {
            // If filterBy is set and this is a new record, set the appropriate fields
            if (filterBy && rowData.isNew) {
                for (const [key, value] of Object.entries(filterBy)) {
                    if (value) {
                        rowData[key] = value;
                    }
                }
            }

            await saveRecord(rowData, rowData[identifierField]);
            await fetchRecords();
            setEditingRow(null);
            setNewRow(null);
            message = "Record saved successfully";
        } catch (error) {
            message = `Error saving record: ${error}`;
        } finally {
            setSaveModal((prev) => ({
                ...prev,
                isProcessing: false,
                message: message,
            }));
        }
    };

    const handleAddNew = () => {
        // Initialize array fields as empty arrays instead of undefined
        let initializedTemplate = { ...newRowTemplate };
        initializedTemplate["uuid"] = crypto.randomUUID();

        // Look through column config to find fields that have custom renderers
        Object.entries(columnConfig).forEach(([key, config]) => {
            const fieldName = config?.field || key;

            // Only initialize as array if it's meant to be an array field
            if (customRenderers[fieldName] && fieldName === "subjects") {
                initializedTemplate[fieldName] =
                    initializedTemplate[fieldName] || [];
            }
        });

        // If filterBy is set, pre-fill the appropriate fields
        if (filterBy) {
            for (const [key, value] of Object.entries(filterBy)) {
                if (value) {
                    initializedTemplate[key] = value;
                }
            }
        }

        setNewRow({ ...initializedTemplate, isNew: true });
    };

    const handleCancel = (rowData) => {
        if (rowData.isNew) {
            setNewRow(null);
        } else {
            setEditingRow(null);
        }
    };

    const handleDelete = async (rowData) => {
        setSaveModal({
            isOpen: true,
            message: "Deleting record...",
            isProcessing: true,
        });

        try {
            console.log({ ...rowData, action: "delete" });

            await saveRecord(
                { ...rowData, action: "delete" },
                rowData[identifierField],
            );
            const updatedRecords = await loadRecords();
            setFilteredRecords(updatedRecords);

            setSaveModal((prev) => ({
                ...prev,
                message: "Record deleted successfully",
                isProcessing: false,
            }));
        } catch (error) {
            setSaveModal((prev) => ({
                ...prev,
                message:
                    error.response?.data?.detail ||
                    error.message ||
                    "Error deleting record",
                isProcessing: false,
            }));
        }
    };

    const renderHeader = (id, label, nextId) => (
        <th
            key={id}
            className={styles.resizableHeader}
            style={{
                width: `${columnWidths[id]}px`,
                minWidth: `${columnWidths[id]}px`,
            }}
        >
            {label}
            {nextId && (
                <div
                    className={styles.resizeHandle}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        startResizing(
                            id,
                            e.clientX,
                            columnWidths[id],
                            nextId,
                            columnWidths[nextId],
                        );
                    }}
                />
            )}
        </th>
    );

    const renderDefaultCell = (rowData, field, type = "text") => {
        const isEditing =
            rowData.isNew ||
            rowData[identifierField] === editingRow?.[identifierField];
        const value = rowData.isNew
            ? newRow?.[field]
            : isEditing
              ? editingRow[field]
              : rowData[field];

        if (!isEditing) {
            return <div>{value}</div>;
        }

        if (field === "phone_number") {
            return (
                <PhoneInput
                    country={"us"}
                    value={value}
                    onChange={(val, country, e, formattedValue) =>
                        handlePhoneChange(
                            rowData,
                            val,
                            country,
                            e,
                            formattedValue,
                        )
                    }
                    inputStyle={{
                        width: "100%",
                        height: "36px",
                        fontSize: "16px",
                        paddingLeft: "48px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        borderBottom: `2px solid ${isPhoneValid ? "#a1a1a1" : "red"}`,
                    }}
                />
            );
        }

        return (
            <input
                type={type}
                value={value || ""}
                onChange={(e) =>
                    handleInputChange(rowData, field, e.target.value)
                }
                style={{ width: "90%" }}
            />
        );
    };

    const renderEditableCell = (rowData, field, type = "text") => {
        // Ensure we have valid input
        if (!rowData || !field) {
            return null;
        }

        // Check if there's a custom renderer for this field
        if (customRenderers[field]) {
            return customRenderers[field](rowData, field, {
                handleInputChange,
                isEditing:
                    rowData.isNew ||
                    rowData[identifierField] === editingRow?.[identifierField],
                newRow,
                editingRow,
            });
        }
        return renderDefaultCell(rowData, field, type);
    };

    // Function to check if all required fields are filled
    const areRequiredFieldsFilled = (rowData) => {
        if (requiredFields.length === 0) return true;
        // console.log(requiredFields);

        return requiredFields.every((field) => {
            const value = rowData[field];
            // Check for empty strings, null, undefined, or empty arrays
            if (value === undefined || value === null || value === "")
                return false;
            if (Array.isArray(value) && value.length === 0) return false;
            return true;
        });
    };

    return (
        <div className={styles.manageSection}>
            <h2>{title}</h2>

            {!filterBy && (
                <div className={styles.filterContainer}>
                    <input
                        type="text"
                        placeholder={`Filter by ${primaryEmailField.replace("_", " ")}...`}
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        className={styles.filterInput}
                    />
                </div>
            )}

            <div className={styles.tableContainer}>
                {isLoading ? (
                    <div className={styles.loadingContainer}>Loading...</div>
                ) : (
                    <table className={styles.resizableTable}>
                        <thead>
                            <tr>
                                {Object.entries(columnConfig).map(
                                    ([id], index, arr) =>
                                        renderHeader(
                                            id,
                                            columnConfig[id].label || id,
                                            index < arr.length - 1
                                                ? arr[index + 1][0]
                                                : null,
                                        ),
                                )}
                                {renderHeader("actions", "Actions", null)}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRecords.map((record) => (
                                <tr key={record[identifierField]}>
                                    {Object.keys(columnConfig).map((field) => (
                                        <td
                                            key={field}
                                            className={styles.scrollableCell}
                                            style={{
                                                width: `${columnWidths[field]}px`,
                                                minWidth: `${columnWidths[field]}px`,
                                            }}
                                        >
                                            <div className={styles.cellContent}>
                                                {renderEditableCell(
                                                    record,
                                                    columnConfig[field].field ||
                                                        field,
                                                    columnConfig[field].type,
                                                )}
                                            </div>
                                        </td>
                                    ))}
                                    <td className={styles.actionsCell}>
                                        {record[identifierField] ===
                                        editingRow?.[identifierField] ? (
                                            <div className={styles.buttonGroup}>
                                                <button
                                                    className={
                                                        styles.btnPrimary
                                                    }
                                                    onClick={() =>
                                                        handleSave(editingRow)
                                                    }
                                                    disabled={
                                                        !areRequiredFieldsFilled(
                                                            editingRow,
                                                        )
                                                    }
                                                    style={
                                                        !areRequiredFieldsFilled(
                                                            editingRow,
                                                        )
                                                            ? {
                                                                  opacity: 0.5,
                                                                  cursor: "not-allowed",
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className={
                                                        styles.btnSecondary
                                                    }
                                                    onClick={() =>
                                                        handleCancel(record)
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        ) : (
                                            <div className={styles.buttonGroup}>
                                                <button
                                                    className={styles.btnEdit}
                                                    onClick={() =>
                                                        setEditingRow(record)
                                                    }
                                                >
                                                    Edit
                                                </button>
                                                {allowDeletion && (
                                                    <button
                                                        className={
                                                            styles.btnDelete
                                                        }
                                                        onClick={() =>
                                                            handleDelete(record)
                                                        }
                                                    >
                                                        Delete
                                                    </button>
                                                )}
                                                {customActions &&
                                                    customActions(record)}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            {newRow && (
                                <tr>
                                    {Object.entries(columnConfig).map(
                                        ([key, config]) => {
                                            const fieldName =
                                                config.field || key;
                                            return (
                                                <td
                                                    key={key}
                                                    className={
                                                        styles.scrollableCell
                                                    }
                                                    style={{
                                                        width: `${columnWidths[key]}px`,
                                                        minWidth: `${columnWidths[key]}px`,
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            styles.cellContent
                                                        }
                                                    >
                                                        {renderEditableCell(
                                                            newRow,
                                                            fieldName,
                                                            config.type,
                                                        )}
                                                    </div>
                                                </td>
                                            );
                                        },
                                    )}
                                    <td className={styles.actionsCell}>
                                        <div className={styles.buttonGroup}>
                                            <button
                                                className={styles.btnPrimary}
                                                onClick={() =>
                                                    handleSave(newRow)
                                                }
                                                disabled={
                                                    !areRequiredFieldsFilled(
                                                        newRow,
                                                    )
                                                }
                                                style={
                                                    !areRequiredFieldsFilled(
                                                        newRow,
                                                    )
                                                        ? {
                                                              opacity: 0.5,
                                                              cursor: "not-allowed",
                                                          }
                                                        : {}
                                                }
                                            >
                                                Save
                                            </button>
                                            <button
                                                className={styles.btnSecondary}
                                                onClick={() =>
                                                    handleCancel(newRow)
                                                }
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>

            {allowAddition && !newRow && (
                <button
                    className={`${styles.btnPrimary} ${styles.addRowButton}`}
                    onClick={handleAddNew}
                >
                    Add New {title.slice(0, -1)}
                </button>
            )}

            <GenericModal
                isOpen={saveModal.isOpen}
                message={saveModal.message}
                confirmMessage={saveModal.isProcessing ? "" : "OK"}
                onConfirm={() =>
                    setSaveModal({
                        isOpen: false,
                        message: "",
                        isProcessing: false,
                    })
                }
                showActions={!saveModal.isProcessing}
            />
        </div>
    );
}

export default ManageAbstract;
