import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PersonalInfoContainer } from "../shared/settings/settings_pages/account_components/PersonalInfo";
import { TestInfoContainer } from "../shared/settings/settings_pages/account_components/TestInfo";
import BottomBar from "./sections/BottomBar";
import ConfirmModal from "../shared/popups/ConfirmModal";
import { SubscriptionOptions } from "./sections/Tiers";

const Apply = () => {
    const navigate = useNavigate();
    const commitmentBlock = false;

    const [settings, setSettings] = useState({
        isCommitted: !commitmentBlock,
        callScheduled: false,
        textOptIn: true,
    });
    const [applyAbility, setApplyAbility] = useState(false);
    const [popupModal, setPopupModal] = useState(false);
    const [failedSubmissionMessage, setFailedSubmissionMessage] = useState(
        "We're sorry, but your application was not successful. Please reach " +
            "out to our team for help.",
    );

    useEffect(() => {
        const requiredSettings = [
            settings?.userFirstName,
            settings?.userEmail,
            settings?.userLastName,
            settings?.focus,
            settings?.goalScore,
            settings?.testDate,
            // settings?.phoneNumber,
        ];

        const allSettingsDefined = requiredSettings.every(
            (setting) =>
                setting !== undefined &&
                setting !== null &&
                setting !== "" &&
                setting !== "none" &&
                setting !== false,
        );

        setApplyAbility(allSettingsDefined);
    }, [settings]);

    return (
        <div className="ws-home-page" style={{ backgroundColor: "#016dea" }}>
            <div className="apply-content">
                <div
                    className="apply-content-top"
                    style={{ maxWidth: "800px" }}
                >
                    <div className="apply-logo-container">
                        <img
                            src="/assets/alia_logo_white.svg"
                            style={{
                                maxWidth: "100px",
                                maxHeight: "50px",
                            }}
                            alt="Alia Logo"
                            className="apply-logo"
                            onClick={() => navigate("/")}
                        />
                    </div>
                    <div
                        className="as-title"
                        style={{
                            padding: "30px 0 30px 0",
                            margin: "0 auto",
                        }}
                    >
                        Apply Now
                    </div>
                    <div className="apply-banner-text">
                        Alia is reserved for a select number of dedicated
                        students ready to reach their goal score. To apply for
                        Alia&apos;s personalized platform and expert tutors,
                        please fill out and submit the application below.
                    </div>
                    <div className="apply-settings-block">
                        <div className="apply-settings-block-title">
                            01. Fill Out Your Personal Info
                        </div>
                        <PersonalInfoContainer
                            settings={settings}
                            setSettings={setSettings}
                            fixedEmail={false}
                            applyPage={true}
                            optIntoText={true}
                        />
                    </div>
                    <div className="apply-settings-block">
                        <div className="apply-settings-block-title">
                            02. Fill Out Your Test Info
                        </div>
                        <TestInfoContainer
                            settings={settings}
                            setSettings={setSettings}
                            applyPage={true}
                        />
                    </div>
                    <div className="apply-settings-block-title">
                        03. Select Your Subscription
                    </div>
                </div>
            </div>
            {applyAbility && (
                <div
                    className="as-container"
                    style={{ backgroundColor: "#016dea", maxWidth: "1600px" }}
                >
                    <div className="as-content">
                        <SubscriptionOptions
                            settings={settings}
                            setSettings={setSettings}
                            applyPage={true}
                            setFailedSubmissionMessage={
                                setFailedSubmissionMessage
                            }
                            setPopupModal={setPopupModal}
                        />
                    </div>
                </div>
            )}
            <BottomBar applyPage={true} />
            <ConfirmModal
                isOpen={popupModal}
                onConfirm={() => setPopupModal(false)}
                message={
                    <div style={{ justifyContent: "center" }}>
                        <div
                            style={{
                                color: "#016dea",
                                fontSize: "24px",
                                marginBottom: "20px",
                            }}
                        >
                            Unsuccessful Application
                        </div>
                        {failedSubmissionMessage}
                    </div>
                }
                confirmMessage={"Go Back"}
            ></ConfirmModal>
        </div>
    );
};
export default Apply;
