import React, { useState, useRef, useEffect } from "react";
import GenericAsync from "../backend/GenericAsync";
import { useAuth } from "./AuthContext";
import { useError } from "../errors/ErrorContext";
import { useSettings } from "./SettingsContext";
import TermsAndConditionsText from "../utils/TermsAndConditionsText";
import "../../website/css/TermsAndConditions.css";

const TermsAndConditions = () => {
    const { settings, setSettings, isModalOpen } = useSettings();
    const [isEnabled, setIsEnabled] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const termsRef = useRef(null);
    const { authEmail } = useAuth();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { triggerError } = useError();

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            setIsEnabled(true); // Enable the button when scrolled to the bottom
        }
    };

    const handleSubmit = async () => {
        const dataToSend = {
            userEmail: authEmail,
        };

        try {
            await GenericAsync({
                backendUrl: `${backendUrl}/api/submit-terms-and-conditions`,
                dataToSend: dataToSend,
            });
            setIsAccepted(true);
        } catch (error) {
            triggerError(error.message);
        }

        setSettings({ ...settings, termsAcknowledged: true });
    };

    useEffect(() => {
        if (settings.termsAcknowledged) {
            setIsAccepted(true);
        }
    }, [settings.termsAcknowledged]);

    useEffect(() => {
        if (!isAccepted) {
            document.body.classList.add("no-scroll");
        }

        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [isAccepted]);

    if (!settings || isAccepted || isModalOpen || settings.termsAcknowledged) {
        return null;
    }

    return (
        <>
            <div className="overlay"></div>
            <div className="terms-container">
                <div
                    className="terms-content"
                    ref={termsRef}
                    onScroll={handleScroll}
                >
                    <div className="terms-header">
                        <h2 className="terms-title">Terms and Conditions</h2>
                    </div>
                    <TermsAndConditionsText />
                    <button
                        className="agree-button"
                        onClick={handleSubmit}
                        disabled={!isEnabled}
                    >
                        Agree
                    </button>
                </div>
            </div>
        </>
    );
};

export default TermsAndConditions;
