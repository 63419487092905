import React from "react";
import { Link } from "react-router-dom";

const BottomBar = ({ applyPage = false }) => {
    return (
        <div
            className="ws-nav-container"
            style={{
                paddingTop: "25px",
                paddingBottom: "25px",
                backgroundColor: applyPage && "#016dea",
            }}
        >
            <div className="bottom-lhs expand-on-1100">
                <div className="ws-logo-container">
                    <img
                        src={
                            applyPage
                                ? "/assets/alia_logo_white.svg"
                                : "/assets/alia_logo_blue.svg"
                        }
                        style={{
                            maxWidth: "88px",
                            height: "50px",
                            cursor: "pointer",
                        }}
                        alt="Alia Logo"
                        className="ws-logo"
                        onClick={() => (window.location.href = "/")}
                    />
                </div>
                <div className="hide-on-mobile-1100">
                    <div
                        className="ws-nav-buttons"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                        }}
                    >
                        {!applyPage && (
                            <>
                                <Link
                                    to="/privacy-policy"
                                    className="ws-nav-button"
                                    style={{
                                        minWidth: "150px",
                                        textDecoration: "none",
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    PRIVACY POLICY
                                </Link>
                                <Link
                                    to="/terms-and-conditions"
                                    className="ws-nav-button"
                                    style={{
                                        minWidth: "100px",
                                        textDecoration: "none",
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    TERMS OF SERVICE
                                </Link>
                                <Link
                                    to="/apply"
                                    className="ws-nav-button"
                                    style={{
                                        minWidth: "110px",
                                        textDecoration: "none",
                                    }}
                                >
                                    APPLY NOW
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="bottom-rhs hide-on-mobile-550">
                <div
                    className="ws-nav-button-bottom"
                    style={{ cursor: "default", color: applyPage && "#FFF" }}
                >
                    TEAM@ALIAEDUCATION.COM&nbsp;&nbsp;|&nbsp;&nbsp;(201)
                    431-5867
                </div>
            </div>
        </div>
    );
};

export default BottomBar;
