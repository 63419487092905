import React, { useState } from "react";
import GenerateModules from "./GenerateModules";
import RenderWithLineBreaks from "../../utils/RenderWithLineBreaks";
import { useUser } from "../../auth/UserContext";
import GenerateTests from "./GenerateTests";
import GenerateProblemSets from "./GenerateProblemSets";
import { useJourney } from "../../common/JourneyContext";
import ConfirmModal from "../../popups/ConfirmModal";

const GenerateContainer = ({ stepType }) => {
    const { profile } = useUser();
    const { generateAbility } = useJourney();
    const [modalOpen, setModalOpen] = useState(true);

    function returnCopy(stepType) {
        let text_copy;
        let stepTypeSingular;
        if (stepType === "tests") {
            stepTypeSingular = "test";
            text_copy =
                "" +
                "Tests are identical in format to the DSAT as administered by College Board. These Tests have four " +
                "modules — two Reading and Writing, followed by two Math. The second module for each question type is adaptive, " +
                "meaning it changes based on your performance on the first module. \n\n" +
                "The default time for a Reading and Writing module is 32 minutes for 27 problems; " +
                "for Math modules, the default time is 35 minutes for 22 problems. " +
                "Here, you can increase the allotted time by 1.5x or 2x, or take the test untimed.\n\n";
        } else if (stepType === "modules") {
            stepTypeSingular = "module";
            text_copy =
                "" +
                "Modules split out the individual test sections for targeted practice. Here, you can complete " +
                "modules for both question types — Reading and Writing, and Math — at three levels of difficulty " +
                "— Foundations, Medium, and Advanced. These modules are administered 1 by " +
                "1, unlike the Tests, which are given four modules at a time. \n\n" +
                "The default time for a Reading and Writing module is 32 minutes for 27 problems; " +
                "for Math modules, the default time is 35 minutes for 22 problems. " +
                "Here, you can increase the allotted time by 1.5x or 2x, or take the module untimed.\n\n";
        } else if (stepType === "problem-sets") {
            stepTypeSingular = "problem set";
            text_copy =
                "" +
                "Problem sets contain a collection of practice problems, organized by category. \n\n";
        }

        if (profile.tutorMode) {
            text_copy +=
                "" +
                "When generating a new module as a tutor, this module will automatically be added to the " +
                "student's Journey.";
        } else {
            text_copy +=
                "" +
                `When you generate a new ${stepTypeSingular}, you will automatically be taken to the problem console to ` +
                `start answering the questions. This ${stepTypeSingular} will also be added to your Journey.`;
        }

        return text_copy;
    }

    const onClosePopup = () => {
        setModalOpen(false); // Simply close the modal
    };

    return (
        <div className={"two-col-body-content"}>
            <div className={"two-col-body-content-text"}>
                {RenderWithLineBreaks(returnCopy(stepType))}
            </div>
            <div
                className={"two-col-body-content-container"}
                style={{ maxWidth: "500px" }}
            >
                <div className={"task-container"} style={{ marginTop: "0px" }}>
                    <div className={"overlap"} style={{ width: "100%" }}>
                        <div className="rectangle">
                            <div>
                                <div className="task-container-title">
                                    {`GENERATE NEW ${stepType.replace(/-/g, " ").toUpperCase().replace(/S$/, "")}`}
                                </div>
                            </div>
                        </div>
                        <div
                            className="current-task-container"
                            style={{
                                justifyContent: "flex-start",
                                minHeight: "140px",
                            }}
                        >
                            {stepType === "modules" && <GenerateModules />}
                            {stepType === "tests" && <GenerateTests />}
                            {stepType === "problem-sets" && (
                                <GenerateProblemSets />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmModal
                isOpen={!generateAbility && modalOpen}
                onConfirm={onClosePopup}
                message={
                    "You have too many ongoing steps! Please fully complete one of " +
                    "them before generating any new content."
                }
                confirmMessage={"Cancel"}
            />
        </div>
    );
};

export default GenerateContainer;
