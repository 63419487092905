import React from "react";
import ManageAbstract from "./ManageAbstract";
import { organizationRenderer, readOnlyRenderer } from "./CustomRenderers";

const columnConfig = {
    id: {
        width: 150,
        label: "ID",
        field: "id",
    },
    name: {
        width: 200,
        label: "Name",
        field: "name",
    },
    // uuid: {
    //     width: 150,
    //     label: "UUID",
    //     field: "uuid",
    // },
    email: {
        width: 200,
        label: "Email",
        type: "email",
    },
    stripe_organization: {
        width: 150,
        label: "Stripe Organization",
        field: "stripe_organization",
    },
    stripe_id: {
        width: 150,
        label: "Stripe ID",
        field: "stripe_id",
    },
};

const newRowTemplate = {
    name: "",
    uuid: crypto.randomUUID(),
    email: "",
    stripe_organization: "",
    stripe_id: "",
};

const customRenderers = {
    name: organizationRenderer,
    stripe_organization: organizationRenderer,
    id: readOnlyRenderer(),
};

function ManageTutors() {
    return (
        <ManageAbstract
            recordType="organization"
            title="Organizations"
            columnConfig={columnConfig}
            newRowTemplate={newRowTemplate}
            customRenderers={customRenderers}
            primaryEmailField="email"
            identifierField="uuid"
        />
    );
}

export default ManageTutors;
