// src/components/LogoutButton.js
import React from "react";
import { useStytch } from "@stytch/react";

export const handleLogout = async (stytchClient) => {
    // Clear local storage items
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("preAuthPath");

    try {
        await stytchClient.session.revoke();
        console.log("Session revoked successfully");
    } catch (error) {
        console.error("Error revoking session:", error);
    }
    window.location.href = window.location.origin;
};

export const LogoutButton = () => {
    const stytchClient = useStytch();

    return (
        <button
            className="logout-button"
            onClick={() => handleLogout(stytchClient)}
        >
            Log Out
        </button>
    );
};
