import { Route, Routes } from "react-router-dom";
import DesktopHomepage from "./components/website/Home";
import HowItWorks from "./components/website/HowItWorks";
import Pricing from "./components/website/Pricing";
import Apply from "./components/website/Apply";
import ApplyConfirmation from "./components/website/sections/ApplyConfirmation";
import TermsAndConditions from "./components/website/TermsAndConditions";
import PrivacyPolicy from "./components/tutoring/PrivacyPolicy";
import ScrollToTop from "./components/shared/utils/ScrollToTop";
import React, { useEffect } from "react";
import SVGSprite from "./assets/website/SVGSprite";

// ExternalRedirect component for redirecting to external URLs
const ExternalRedirect = ({ to }) => {
    useEffect(() => {
        window.location.href = to;
    }, [to]);

    return null;
};

import {
    CheckoutReturn,
    CheckoutForm,
} from "./components/shared/stripe/Checkout";
import SignupFlow from "./components/website/signup/SignupFlow";

function Website() {
    function SiteRoutes() {
        return (
            <>
                <ScrollToTop />
                <SVGSprite />
                <CheckoutReturn />
                <Routes>
                    <Route path="/" element={<DesktopHomepage />} />
                    <Route path="/blog" element={<DesktopHomepage />} />
                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/apply" element={<Apply />} />
                    <Route
                        path="/apply-confirmation"
                        element={<ApplyConfirmation />}
                    />
                    <Route path="/checkout" element={<CheckoutForm />} />
                    <Route path="/new-student" element={<SignupFlow />} />
                    <Route
                        path="/signup"
                        element={
                            <ExternalRedirect to="https://sat.aliaeducation.com" />
                        }
                    />
                    <Route
                        path="/terms-and-conditions"
                        element={<TermsAndConditions />}
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
            </>
        );
    }

    return <SiteRoutes />;
}

export default Website;
