import React, { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import GenericAsync from "../../shared/backend/GenericAsync";
import styles from "./SignupStyles.module.css";

export default function StripeAuthorization({ studentData }) {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    );

    const fetchClientSecret = useCallback(async () => {
        try {
            const data = await GenericAsync({
                backendUrl: `${backendUrl}/tutoring-api/create-new-client`,
                dataToSend: {
                    userEmail: studentData.email,
                    amount: 100, // $1.00 in cents
                    isAuthorization: true,
                },
            });

            return data.clientSecret;
        } catch (error) {
            console.error("Error creating authorization session:", error);
            return null;
        }
    }, [backendUrl, studentData.email]);

    const options = { fetchClientSecret };

    return (
        <div className={styles.container}>
            <div className={styles.formTitle}>Payment Authorization</div>
            <p className={styles.formDescription}>
                Please authorize your card for future tutoring sessions. A
                temporary $1.00 hold will be placed on your card and released
                within 7 days.
            </p>

            <div className={styles.stripeWrapper}>
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={options}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </div>
        </div>
    );
}
