import React from "react";
import "./css/Home.css";
import "./sections/Hero.css";
import TopBanner from "./sections/TopBanner";
import Everything from "./sections/Everything";
import NavBar from "./sections/NavBar";
import BottomBar from "./sections/BottomBar";
import Reviews from "./sections/Reviews";
import Invest from "./sections/Invest";
import ComparisonTable from "./sections/ComparisonTable";
import FAQ from "./sections/FAQ";
import Tiers from "./sections/Tiers";

const Pricing = () => {
    return (
        <div className="ws-home-page">
            <TopBanner />
            <NavBar />
            <Invest />
            <Everything pricing={true} />
            <Tiers />
            <ComparisonTable />
            <FAQ />
            <Reviews pricing={false} />
            <BottomBar />
        </div>
    );
};

export default Pricing;
