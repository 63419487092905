import React from "react";
import ManageAbstract from "./ManageAbstract";
import {
    uuidRenderer,
    readOnlyRenderer,
    transferTypeRenderer,
} from "./CustomRenderers";

const columnConfig = {
    // uuid: {
    //     width: 150,
    //     label: "UUID",
    //     field: "uuid",
    // },
    id: {
        width: 150,
        label: "ID",
        field: "id",
    },
    recipient_email: {
        width: 150,
        label: "Recipient Email",
        field: "recipient_email",
    },
    // session_uuid: {
    //     width: 150,
    //     label: "Session UUID",
    //     field: "session_uuid",
    // },
    // stripe_id: {
    //     width: 150,
    //     label: "Stripe ID",
    //     field: "stripe_id",
    // },
    // source_charge: {
    //     width: 150,
    //     label: "Source Charge",
    //     field: "source_charge",
    // },
    amount: {
        width: 150,
        label: "Amount ($)",
        field: "amount",
    },
    status: {
        width: 150,
        label: "Status",
        field: "status",
    },
    // recipient_id: {
    //     width: 200,
    //     label: "Recipient ID",
    //     field: "recipient_id",
    // },
    type: {
        width: 150,
        label: "Type",
        field: "type",
    },
    description: {
        width: 250,
        label: "Description",
        field: "description",
    },
};

const newRowTemplate = {
    uuid: crypto.randomUUID(),
    session_uuid: "",
    stripe_id: "",
    source_charge: "",
    amount: "",
    status: "",
    recipient_id: "",
    type: "",
    description: "",
};

const customRenderers = {
    uuid: uuidRenderer,
    session_uuid: uuidRenderer,
    id: readOnlyRenderer(),
    recipient_email: readOnlyRenderer(),
    amount: readOnlyRenderer(),
    status: readOnlyRenderer(true),
    type: transferTypeRenderer,
};

function ManageTransfers() {
    return (
        <ManageAbstract
            recordType="transfer"
            title="Transfers"
            columnConfig={columnConfig}
            newRowTemplate={newRowTemplate}
            customRenderers={customRenderers}
            primaryEmailField="recipient_email"
            identifierField="uuid"
            requiredFields={["session_uuid", "status", "recipient_id"]}
        />
    );
}

export default ManageTransfers;
