import React, { useEffect, useState } from "react";
import { useUser } from "./UserContext";
import "./Auth.css";

const StudentSelector = () => {
    const { profile, studentDict, selectUserProfile } = useUser();
    const [selectedStudent, setSelectedStudent] = useState("");

    useEffect(() => {
        // Set the default selection to the Auth0 user's email
        setSelectedStudent(profile.email);
    }, [profile.email]);

    const handleSelect = (event) => {
        const email = event.target.value;
        const studentName = studentDict[email];
        selectUserProfile(email, studentName);
        setSelectedStudent(email);
    };

    if (!studentDict || Object.keys(studentDict).length === 0) {
        return null;
    }

    // Prepare options for rendering
    const studentOptions = Object.entries(studentDict).map(([email, name]) => {
        return (
            <option key={email} value={email}>
                {name} {email !== profile.email && ` (${email})`}
            </option>
        );
    });

    return (
        <select
            onChange={handleSelect}
            value={selectedStudent}
            className="student-selector"
        >
            {studentOptions}
        </select>
    );
};

export default StudentSelector;
