const GenericAsync = async ({
    backendUrl,
    dataToSend,
    jsonify = true,
    method = "POST",
}) => {
    const options = {
        method,
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Only add body for POST/PUT/PATCH requests
    if (["POST", "PUT", "PATCH"].includes(method)) {
        options.body = JSON.stringify(dataToSend);
    }

    const response = await fetch(backendUrl, options);

    if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
    }

    if (jsonify) {
        // Use text() and JSON.parse() instead of json() to ensure order preservation
        const text = await response.text();
        return JSON.parse(text);
    } else {
        return response;
    }
};

export default GenericAsync;
