import React from "react";
import "./Features.css";
import { useNavigate } from "react-router-dom";

const Features = () => {
    const navigate = useNavigate();
    return (
        <div className="features-background">
            <div className="curve-wrapper">
                <div className="curved-background curved-background-mobile"></div>
            </div>
            <div className="features-content">
                <div className="features-icons">
                    <div
                        className="feature-item"
                        style={{ textAlign: "center" }}
                    >
                        <img
                            src="/assets/website/profile_icon_invert.svg"
                            alt="1:1 Tutoring & Support"
                        />
                        <span>1:1 TUTORING & SUPPORT</span>
                    </div>
                    <div
                        className="feature-item"
                        style={{ textAlign: "center" }}
                    >
                        <img
                            src="/assets/website/alia_icon_checklist.svg"
                            alt="CURATED Content"
                        />
                        <span>CURATED CONTENT</span>
                    </div>
                    <div
                        className="feature-item"
                        style={{ textAlign: "center" }}
                    >
                        <img
                            src="/assets/website/alia_icon_stopwatch.svg"
                            alt="Session Maximization"
                        />
                        <span>SESSION MAXIMIZATION</span>
                    </div>
                    <div
                        className="feature-item"
                        style={{ textAlign: "center" }}
                    >
                        <img
                            src="/assets/website/alia_icon_chart.svg"
                            alt="Insightful Analytics"
                        />
                        <span>INSIGHTFUL ANALYTICS</span>
                    </div>
                </div>
                <div className="feature-text-container">
                    <div className="as-title">
                        Your Bootcamp for SAT Success
                    </div>
                    <div
                        className="as-text"
                        style={{
                            textAlign: "left",
                        }}
                    >
                        <div>
                            At Alia, we&apos;re a team of no-nonsense tutors who
                            specialize in delivering results. With us, you
                            won&apos;t find any AI chat bots, mediocre content,
                            or lack of accountability. Instead, we&apos;ve built
                            a study program based on the following principles:
                        </div>
                        <ol>
                            <li>
                                The guidance of an expert human tutor is
                                irreplaceable for maximizing student outcomes.
                            </li>
                            <li>
                                Study material must be of the highest quality.
                            </li>
                            <li>Student buy-in is necessary for success.</li>
                        </ol>
                        <div>
                            If this sounds like an approach that aligns with
                            your study methods, we invite you to apply for our
                            Alia SAT Bootcamp! Given our commitment to
                            delivering customized, effective solutions to each
                            student, we&apos;re limited in the number of
                            applicants we can accept, and we recommend reaching
                            out as soon as possible.
                        </div>
                    </div>
                    <div className="alia-def-block">
                        <div className="alia-def-1">a·li·a | adv.</div>
                        <div className="alia-def-2">def: by another way</div>
                    </div>

                    <button
                        className="apply-button"
                        style={{
                            color: "#003169",
                            backgroundColor: "#FFAB24",
                        }}
                        onClick={() => navigate("/apply")}
                    >
                        APPLY NOW
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Features;
