import React from "react";

const ErrorPage = () => {
    return (
        <div className="desktop-homepage">
            <div className={"main-content"}>
                <div
                    className="main-body-text"
                    style={{ marginBottom: "40px" }}
                >
                    <div
                        className="welcome-text"
                        style={{ fontSize: "24px", color: "#016dea" }}
                    >
                        Apologies! Something went wrong.
                    </div>
                    <div className="welcome-text">
                        <p>
                            We&apos;re sorry, but an unexpected error occurred.
                            Please try again later.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
