import React from "react";
import styles from "./SignupStyles.module.css";

export default function NavigationControls({
    currentStep,
    totalSteps,
    onPrevious,
    onNext,
    canProceed,
}) {
    return (
        <div className={styles.navigationControls}>
            <button
                className={styles.navButton}
                onClick={onPrevious}
                disabled={currentStep === 1}
            >
                &lt;&lt;
            </button>
            <span className={styles.pageIndicator}>
                {currentStep}/{totalSteps}
            </span>
            <button
                className={styles.navButton}
                onClick={onNext}
                disabled={currentStep === totalSteps || !canProceed}
            >
                &gt;&gt;
            </button>
        </div>
    );
}
