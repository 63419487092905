import React from "react";
import ManageAbstract from "./ManageAbstract";
import { organizationRenderer, readOnlyRenderer } from "./CustomRenderers";

const columnConfig = {
    id: {
        width: 50,
        label: "ID",
        field: "id",
    },
    first_name: {
        width: 150,
        label: "First Name",
        field: "first_name",
    },
    last_name: {
        width: 150,
        label: "Last Name",
        field: "last_name",
    },
    email: {
        width: 200,
        label: "Email",
        type: "email",
    },
    organization: {
        width: 150,
        label: "Organization",
        field: "organization",
    },
    stripe_id: {
        width: 150,
        label: "Stripe ID",
        field: "stripe_id",
    },
};

const newRowTemplate = {
    email: "",
    uuid: crypto.randomUUID(),
    first_name: "",
    last_name: "",
    stripe_user_id: "",
    organization: "",
};

const customRenderers = {
    organization: organizationRenderer,
    id: readOnlyRenderer(),
};

function ManageReferrers() {
    return (
        <ManageAbstract
            recordType="referrer"
            title="Referrers"
            columnConfig={columnConfig}
            newRowTemplate={newRowTemplate}
            customRenderers={customRenderers}
            primaryEmailField="email"
            identifierField="uuid"
        />
    );
}

export default ManageReferrers;
