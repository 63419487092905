import React, { useState, useEffect } from "react";
import { useRecordManagement } from "../admin/hooks/useRecordManagement";
import { useLocation } from "react-router-dom";
import ConfirmModal from "../shared/popups/ConfirmModal";
import "react-phone-input-2/lib/style.css";
import "./css/TutoringStyles.css";
import GenericAsync from "../shared/backend/GenericAsync";
import { useTutoringUser } from "../shared/auth/TutoringContext";

function MyInfo() {
    const { userInfo } = useTutoringUser();
    const location = useLocation();
    const { loadRecord, saveRecord } = useRecordManagement("tutor");
    const [record, setRecord] = useState(null);
    const [editingRecord, setEditingRecord] = useState(null);
    const [originalRecord, setOriginalRecord] = useState(null);
    const [isRecordLoading, setIsRecordLoading] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [stripeConnected, setStripeConnected] = useState(false);
    const [zoomConnected, setZoomConnected] = useState(false);
    const [googleCalendarConnected, setGoogleCalendarConnected] =
        useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [modal, setModal] = useState({
        isOpen: false,
        message: "",
    });
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        if (userInfo?.email || userInfo?.role) {
            setIsRecordLoading(true);
            loadRecord(userInfo?.role, userInfo?.uuid)
                .then((data) => {
                    if (Array.isArray(data) && data.length > 0) {
                        setRecord(data[0]);
                        setOriginalRecord(data[0]);
                        setStripeConnected(!!data[0]?.stripe_id);
                        setZoomConnected(!!data[0]?.zoom_user_id);
                        setGoogleCalendarConnected(
                            !!data[0]?.calendar_connected,
                        );
                    } else {
                        console.log("No tutor record found");
                    }
                })
                .catch((error) => {
                    console.log(
                        "No tutor record found or error loading:",
                        error,
                    );
                })
                .finally(() => {
                    setIsRecordLoading(false);
                });
        }
    }, [userInfo?.email, location.key]);

    useEffect(() => {
        if (editingRecord && originalRecord) {
            const hasChanges =
                JSON.stringify(editingRecord) !==
                JSON.stringify(originalRecord);
            setHasUnsavedChanges(hasChanges);
        } else {
            setHasUnsavedChanges(false);
        }
    }, [editingRecord, originalRecord]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (hasUnsavedChanges) {
                e.preventDefault();
                e.returnValue =
                    "You have unsaved changes. Are you sure you want to leave?";
            }
        };

        // Store click handler as a reference so we can remove it
        const handleClick = (e) => {
            const anchor = e.target.closest("a");
            if (anchor && hasUnsavedChanges) {
                if (
                    !window.confirm(
                        "You have unsaved changes. Are you sure you want to leave?",
                    )
                ) {
                    e.preventDefault();
                } else {
                    // User clicked "OK" to leave - clean up the state
                    setIsEditing(false);
                    setEditingRecord(originalRecord);
                    setHasUnsavedChanges(false);
                    // Remove the listeners immediately after confirming
                    window.removeEventListener(
                        "beforeunload",
                        handleBeforeUnload,
                    );
                    window.removeEventListener("click", handleClick, true);
                }
            }
        };

        if (hasUnsavedChanges) {
            window.addEventListener("beforeunload", handleBeforeUnload);
            window.addEventListener("click", handleClick, true);
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            window.removeEventListener("click", handleClick, true);
        };
    }, [hasUnsavedChanges]);

    const handleEdit = () => {
        setEditingRecord(record);
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            await saveRecord(editingRecord, userInfo?.email);
            setRecord(editingRecord);
            setOriginalRecord(editingRecord);
            setIsEditing(false);
            setHasUnsavedChanges(false);
            setModal({
                isOpen: true,
                message: "Information updated successfully",
            });
        } catch (error) {
            setModal({
                isOpen: true,
                message: "Error updating information",
            });
        }
    };

    const handleCancel = () => {
        setEditingRecord(null);
        setIsEditing(false);
    };

    const handleInputChange = (field, value) => {
        setEditingRecord((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const connectStripe = () => {
        const connectParams = new URLSearchParams({
            response_type: "code",
            client_id: process.env.REACT_APP_STRIPE_CONNECT_ID,
            scope: "read_write",
            redirect_uri: "https://tutoring.aliaeducation.com/stripe-callback",
            state: userInfo?.email,
        });

        const url = `https://connect.stripe.com/oauth/authorize?${connectParams.toString()}`;
        window.location.href = url;
    };

    const connectZoom = async () => {
        try {
            // Show loading modal immediately when connect is pressed
            setModal({
                isOpen: true,
                message: "Connecting to Zoom...",
            });

            const response = await fetch(
                `${backendUrl}/tutoring-api/create-zoom-user`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: userInfo?.email,
                        first_name: record?.first_name || "",
                        last_name: record?.last_name || "",
                    }),
                },
            );

            const data = await response.json();

            if (!response.ok) {
                throw new Error(
                    data.detail || "Failed to connect Zoom account",
                );
            }

            // Use the message from the endpoint response
            setModal({
                isOpen: true,
                message: data.message || "Request processed",
            });

            if (data.status === "exists" || data.status === "created") {
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
        } catch (error) {
            console.error("Error connecting Zoom:", error);
            setModal({
                isOpen: true,
                message: "Request failed ... please reach out to support",
            });
        }
    };

    const connectGoogleCalendar = async () => {
        try {
            setModal({
                isOpen: true,
                message: "Connecting to Google Calendar...",
            });

            const response = await fetch(
                `${backendUrl}/tutoring-api/initiate-google-auth`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: userInfo?.email,
                    }),
                },
            );

            const data = await response.json();

            if (!response.ok) {
                throw new Error(
                    data.detail || "Failed to connect Google Calendar",
                );
            }

            // Redirect to Google OAuth URL
            window.location.href = data.auth_url;
        } catch (error) {
            console.error("Error connecting Google Calendar:", error);
            setModal({
                isOpen: true,
                message: "Failed to connect Google Calendar. Please try again.",
            });
        }
    };

    // Handle Google OAuth callback
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get("code");
        const state = urlParams.get("state");

        // Only try to refresh token if we have OAuth parameters
        if (code && state) {
            if (userInfo?.email) {
                setModal({
                    isOpen: true,
                    message: "Processing Google Calendar connection...",
                });

                GenericAsync({
                    backendUrl: `${backendUrl}/tutoring-api/complete-google-auth`,
                    dataToSend: { code, state, email: userInfo?.email },
                    method: "POST",
                })
                    .then(() => {
                        setModal({
                            isOpen: true,
                            message:
                                "Successfully connected to Google Calendar!",
                        });
                        const currentPath = window.location.pathname;
                        setGoogleCalendarConnected(true);
                        window.history.replaceState(
                            {},
                            document.title,
                            currentPath,
                        );
                    })
                    .catch((error) => {
                        console.error("Google Auth failed:", error);
                        setModal({
                            isOpen: true,
                            message: `Failed to connect Google Calendar: ${error.message}`,
                        });
                        window.history.replaceState(
                            {},
                            document.title,
                            window.location.pathname,
                        );
                    });
            }
        }
    }, [location.search, userInfo?.email]);

    return (
        <div className="tutoring-container">
            <h1 className="tutoring-title">My Information</h1>
            <div className="my-info-grid">
                <div className="info-field">
                    <label>Email</label>
                    <div className="info-value">{userInfo?.email}</div>
                </div>

                <div className="info-field">
                    <label>First Name</label>
                    {isEditing ? (
                        <input
                            type="text"
                            value={editingRecord?.first_name || ""}
                            onChange={(e) =>
                                handleInputChange("first_name", e.target.value)
                            }
                        />
                    ) : (
                        <div className="info-value">{record?.first_name}</div>
                    )}
                </div>

                <div className="info-field">
                    <label>Last Name</label>
                    {isEditing ? (
                        <input
                            type="text"
                            value={editingRecord?.last_name || ""}
                            onChange={(e) =>
                                handleInputChange("last_name", e.target.value)
                            }
                        />
                    ) : (
                        <div className="info-value">{record?.last_name}</div>
                    )}
                </div>
                <div className="info-field">
                    <label>Organization</label>
                    <div className="info-value">
                        {record?.organization === "alia"
                            ? "Alia Education"
                            : "Kindred Tutoring"}
                    </div>
                </div>
                <div className="info-field">
                    <label>Stripe Connection</label>
                    {!isRecordLoading &&
                        (stripeConnected ? (
                            <div className="info-value">
                                ✓ Connected to Stripe
                            </div>
                        ) : (
                            <button
                                className="stripe-connect-button"
                                onClick={connectStripe}
                            >
                                Connect with Stripe
                            </button>
                        ))}
                </div>
                {userInfo?.role !== "referrer" && (
                    <>
                        <div className="info-field">
                            <label>Zoom Connection</label>
                            {!isRecordLoading &&
                                (zoomConnected ? (
                                    <div className="info-value">
                                        ✓ Connected to Zoom
                                    </div>
                                ) : (
                                    <button
                                        className="zoom-connect-button"
                                        onClick={connectZoom}
                                    >
                                        Connect to Zoom
                                    </button>
                                ))}
                        </div>
                        <div className="info-field">
                            <label>Google Calendar Connection</label>
                            {!isRecordLoading &&
                                (googleCalendarConnected ? (
                                    <div className="info-value">
                                        ✓ Connected to Google Calendar
                                    </div>
                                ) : (
                                    <button
                                        className="google-calendar-connect-button"
                                        onClick={connectGoogleCalendar}
                                    >
                                        Connect to Google Calendar
                                    </button>
                                ))}
                        </div>
                    </>
                )}
            </div>

            <div className="button-group">
                {isEditing ? (
                    <>
                        <button className="save-button" onClick={handleSave}>
                            Save
                        </button>
                        <button
                            className="cancel-button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    </>
                ) : (
                    <button className="edit-button" onClick={handleEdit}>
                        Edit Info
                    </button>
                )}
            </div>

            <ConfirmModal
                isOpen={modal.isOpen}
                message={modal.message}
                confirmMessage="OK"
                onConfirm={() => setModal({ isOpen: false, message: "" })}
            />
        </div>
    );
}

export default MyInfo;
