import React from "react";
import "./css/PrivacyPolicy.css";

const TermsOfService = () => {
    return (
        <div className="terms-service-container">
            <div className="terms-service-content">
                <div className="terms-service-header">
                    <img
                        src="/assets/alia_logo_blue.svg"
                        alt="Alia Logo"
                        className="terms-service-logo"
                    />
                    <h1 className="terms-service-title">
                        Privacy Policy for Alia Calendar Integration App
                    </h1>
                </div>

                <div className="terms-service-body">
                    <section className="terms-section">
                        <h2>Introduction</h2>
                        <p>
                            This Privacy Policy describes how the Alia Calendar
                            Integration App (&ldquo;the App&rdquo;) collects,
                            uses, and shares your information when you use our
                            service to automatically add Zoom links to your
                            Google Calendar events.
                        </p>
                    </section>

                    <section className="terms-section">
                        <h2>Information We Collect</h2>
                        <p>When you authorize our App, we request access to:</p>
                        <ul>
                            <li>
                                Your Google Calendar events
                                (https://www.googleapis.com/auth/calendar.events)
                            </li>
                        </ul>
                        <p>This permission allows us to:</p>
                        <ul>
                            <li>View your existing calendar events</li>
                            <li>
                                Add Zoom meeting links to calendar events you
                                schedule as a tutor
                            </li>
                            <li>
                                Update event details related to tutoring
                                sessions
                            </li>
                        </ul>
                        <p>We do not collect or store:</p>
                        <ul>
                            <li>
                                Personal contact information beyond what&apos;s
                                necessary for calendar functionality
                            </li>
                            <li>
                                The content of your communications in these
                                meetings
                            </li>
                            <li>
                                Information about calendar events unrelated to
                                tutoring sessions
                            </li>
                        </ul>
                    </section>

                    <section className="terms-section">
                        <h2>How We Use Your Information</h2>
                        <p>The information we access is used solely for:</p>
                        <ul>
                            <li>
                                Automatically generating and adding Zoom meeting
                                links to tutoring events in your calendar
                            </li>
                            <li>
                                Ensuring the proper functioning of the calendar
                                integration service
                            </li>
                        </ul>
                    </section>

                    <section className="terms-section">
                        <h2>Data Storage and Retention</h2>
                        <p>
                            We do not retain any Google user data. The App only
                            processes your calendar data temporarily to add Zoom
                            links and does not permanently store your calendar
                            information in any way. We do not create any
                            persistent records of your Google Calendar data.
                        </p>
                        <p>
                            Any temporary data processing is secured and deleted
                            immediately once the Zoom link has been added to
                            your calendar event. After the operation is
                            complete, no trace of your Google Calendar data
                            remains in our systems.
                        </p>
                    </section>

                    <section className="terms-section">
                        <h2>Data Sharing</h2>
                        <p>
                            We do not sell, rent, or share your information with
                            third parties.
                        </p>
                    </section>

                    <section className="terms-section">
                        <h2>Your Rights</h2>
                        <p>
                            You can revoke access to your Google Calendar at any
                            time by:
                        </p>
                        <ul>
                            <li>
                                Visiting your Google Account settings at
                                https://myaccount.google.com/permissions
                            </li>
                            <li>
                                Locating our App in the &ldquo;Third-party apps
                                with account access&rdquo; section
                            </li>
                            <li>Selecting &ldquo;Remove Access&rdquo;</li>
                        </ul>
                        <p>
                            Since we do not retain any Google user data, there
                            is no additional data to delete upon revocation of
                            access. Once access is revoked, our App will no
                            longer be able to view or modify your calendar
                            events.
                        </p>
                    </section>

                    <section className="terms-section">
                        <h2>Changes to This Policy</h2>
                        <p>
                            We may update this Privacy Policy from time to time.
                            We will notify you of any changes by posting the new
                            Privacy Policy on this page.
                        </p>
                    </section>

                    <section className="terms-section">
                        <h2>Contact Us</h2>
                        <p>
                            If you have any questions about this Privacy Policy,
                            please contact us at team@aliaeducation.com.
                        </p>
                    </section>

                    <div className="terms-service-footer">
                        <p>Last Updated: March 13, 2025</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;
