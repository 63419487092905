import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./components/admin/Home";
import "./components/tutoring/css/TutoringStyles.css";
import { useAuth } from "./components/shared/auth/AuthContext";
import NotFound from "./components/shared/auth/NotFound";
import { handleLogout } from "./components/shared/auth/LogoutButton";
import { getBasePath } from "./components/shared/utils/DomainUtils";
import Dashboard from "./components/admin/Dashboard";
import { useStytch } from "@stytch/react";

function Admin() {
    const { authInfo } = useAuth();
    const basePath = getBasePath("admin");
    const stytchClient = useStytch();

    if (!authInfo) {
        return <div>Loading...</div>;
    }

    // Only allow admin users to access this section
    if (authInfo?.role !== "admin") {
        return <NotFound />;
    }

    return (
        <div className="tutoring-layout">
            <nav className="tutoring-sidebar">
                <ul className="tutoring-menu">
                    <li className="tutoring-menu-item">
                        <Link className="tutoring-menu-link" to={`${basePath}`}>
                            Home
                        </Link>
                    </li>
                    <li className="tutoring-menu-item">
                        <Link
                            className="tutoring-menu-link"
                            to={`${basePath}/dashboard/sessions`}
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="tutoring-menu-item">
                        <button
                            className="tutoring-menu-link"
                            onClick={() => handleLogout(stytchClient)}
                        >
                            Logout
                        </button>
                    </li>
                </ul>
            </nav>
            <main className="tutoring-content">
                <Routes>
                    <Route path={`${basePath}/`} element={<Home />} />
                    <Route
                        path={`${basePath}/dashboard/*`}
                        element={<Dashboard tutoringPlatform={false} />}
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </main>
        </div>
    );
}

export default Admin;
