import React, { useState, useEffect, useMemo } from "react";
import ManageAbstract from "./ManageAbstract";
import styles from "../css/AdminStyles.module.css";
import { formatTimezone } from "../utils/dateUtils";
import { useRecordManagement } from "../hooks/useRecordManagement";
import { format } from "date-fns";
import GenericAsync from "../../shared/backend/GenericAsync";
import ConfirmModal from "../../shared/popups/ConfirmModal";
import {
    dateTimeRenderer,
    endDateTimeRenderer,
    statusRenderer,
    relationshipRenderer,
    uuidRenderer,
    readOnlyRenderer,
    emailSelectorRenderer,
} from "./CustomRenderers";
import { useTutoringUser } from "../../shared/auth/TutoringContext";
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const baseColumnConfig = {
    id: {
        width: 50,
        label: "ID",
        field: "id",
    },
    tutor_email: {
        width: 200,
        label: "Tutor Email",
        field: "tutor_email",
        type: "email",
    },
    client_email: {
        width: 250,
        label: "Client Email",
        field: "client_email",
    },
    relationship_uuid: {
        width: 200,
        label: "Relationship",
        field: "relationship_uuid",
    },
    start_datetime: {
        width: 200,
        label: `Start Time (${formatTimezone(timezone)})`,
        field: "start_datetime",
        type: "datetime-local",
    },
    end_datetime: {
        width: 200,
        label: `End Time (${formatTimezone(timezone)})`,
        field: "end_datetime",
        type: "datetime-local",
    },
    client_rate: {
        width: 100,
        label: "Client Rate",
        field: "client_rate",
        type: "number",
    },
    tutor_rate: {
        width: 100,
        label: "Tutor Rate",
        field: "tutor_rate",
        type: "number",
    },
    title: {
        width: 200,
        label: "Title",
        field: "title",
    },
    description: {
        width: 200,
        label: "Description",
        field: "description",
    },
    url: {
        width: 200,
        label: "URL",
        field: "url",
    },
    status: {
        width: 150,
        label: "Status",
        field: "status",
    },
};

const newRowTemplate = {
    uuid: crypto.randomUUID(),
    gcal_id: "",
    series_uuid: "",
    start_datetime: "",
    end_datetime: "",
    title: "",
    description: "",
    url: "",
    client_rate: "",
    tutor_rate: "",
    tutor_email: "",
    client_email: "",
    relationship_uuid: "",
    status: "scheduled",
};

function ManageSessions({ tutoringPlatform = false }) {
    const { userInfo } = useTutoringUser();
    const [paymentModal, setPaymentModal] = useState({
        isOpen: false,
        message: "",
        isProcessing: false,
        isConfirmation: false,
        currentSession: null,
    });
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const relationshipManagement = useRecordManagement("relationship");
    const clientManagement = useRecordManagement("client");
    const tutorManagement = useRecordManagement("tutor");

    const [tutorEmails, setTutorEmails] = useState([]);
    const [clientEmails, setClientEmails] = useState([]);
    const [relationships, setRelationships] = useState([]);

    // Create a filtered columnConfig based on tutoringPlatform prop
    const columnConfig = useMemo(() => {
        if (!tutoringPlatform) {
            return baseColumnConfig;
        }

        // Create a new object without the tutor_email and client_rate fields
        const filteredConfig = { ...baseColumnConfig };
        delete filteredConfig.client_rate;
        delete filteredConfig.tutor_email;
        delete filteredConfig.description;

        // Rename tutor_rate label to "Hourly Rate"
        if (filteredConfig.tutor_rate) {
            filteredConfig.tutor_rate = {
                ...filteredConfig.tutor_rate,
                label: "Hourly Rate",
            };
        }

        return filteredConfig;
    }, [tutoringPlatform]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const [relationshipData] = await Promise.all([
                    relationshipManagement.loadRecords(),
                ]);
                setRelationships(relationshipData || []);
                if (!tutoringPlatform) {
                    const [tutorData, clientData] = await Promise.all([
                        tutorManagement.loadRecords(),
                        clientManagement.loadRecords(),
                    ]);
                    setTutorEmails(tutorData || []);
                    setClientEmails(clientData || []);
                }
            } catch (error) {
                console.error("Error loading data:", error);
            }
        };

        loadData();
    }, []);

    const handlePayout = async (rowData) => {
        // First show confirmation modal
        const startTime = rowData.start_datetime
            ? format(new Date(rowData.start_datetime), "MMM d, yyyy h:mm aa")
            : "N/A";
        const endTime = rowData.end_datetime
            ? format(new Date(rowData.end_datetime), "MMM d, yyyy h:mm aa")
            : "N/A";

        const confirmationMessage = `
            Confirm that you'd like to charge the client for the following session:

            Tutor email: ${rowData.tutor_email}
            Client email: ${rowData.client_email}
            
            Start time: ${startTime}
            End time: ${endTime}

            ${
                !tutoringPlatform
                    ? `
                Client Rate: $${rowData.client_rate || 0}`
                    : ""
            }
            ${tutoringPlatform ? `Hourly Rate` : `Tutor Rate`}: $${rowData.tutor_rate || 0}
        `;

        setPaymentModal({
            isOpen: true,
            message: confirmationMessage,
            isProcessing: false,
            isConfirmation: true,
            currentSession: rowData,
        });
    };

    const processPayment = async () => {
        const rowData = paymentModal.currentSession;
        if (!rowData) return;

        try {
            // Update modal to processing state
            setPaymentModal({
                ...paymentModal,
                message: "Processing payment...",
                isProcessing: true,
                isConfirmation: false,
            });

            // Call the payment intent endpoint using GenericAsync
            await GenericAsync({
                backendUrl: `${process.env.REACT_APP_BACKEND_URL}/tutoring-api/create-payment-intent`,
                dataToSend: {
                    session_uuid: rowData.uuid,
                },
                method: "POST",
                jsonify: true,
            });

            // Show success message
            setPaymentModal({
                ...paymentModal,
                message: "Payment processed successfully!",
                isProcessing: false,
                isConfirmation: false,
            });

            // Trigger a refresh by updating the state
            setRefreshTrigger((prev) => prev + 1);
        } catch (error) {
            console.error("Error processing payment:", error);

            // Show error message
            setPaymentModal({
                ...paymentModal,
                message: `Payment processing failed: ${error.message}`,
                isProcessing: false,
                isConfirmation: false,
            });
        }
    };

    const closePaymentModal = () => {
        setPaymentModal({
            isOpen: false,
            message: "",
            isProcessing: false,
            isConfirmation: false,
            currentSession: null,
        });
    };

    const customActions = (rowData) => {
        // Only show payout button for completed or client no-show sessions
        if (
            rowData.status === "completed" ||
            rowData.status === "client_no_show" ||
            rowData.status === "late_cancel"
        ) {
            return (
                <button
                    className={styles.btnPayout}
                    onClick={() => handlePayout(rowData)}
                >
                    Pay Out
                </button>
            );
        }
        return null;
    };

    const customRenderers = {
        id: readOnlyRenderer(),
        tutor_email: tutoringPlatform
            ? readOnlyRenderer()
            : emailSelectorRenderer(tutorEmails),
        client_email: tutoringPlatform
            ? readOnlyRenderer()
            : emailSelectorRenderer(clientEmails),
        relationship_uuid: relationshipRenderer(relationships),
        uuid: uuidRenderer,
        start_datetime: dateTimeRenderer,
        end_datetime: endDateTimeRenderer,
        status: statusRenderer,
        tutor_rate: tutoringPlatform ? readOnlyRenderer() : null,
    };

    console.log(relationships);

    return (
        <>
            <ManageAbstract
                key={`sessions-${refreshTrigger}`}
                recordType="session"
                title="Sessions"
                columnConfig={columnConfig}
                newRowTemplate={newRowTemplate}
                customRenderers={customRenderers}
                primaryEmailField="tutor_email"
                identifierField="uuid"
                customActions={customActions}
                allowAddition={!tutoringPlatform}
                requiredFields={[
                    "tutor_email",
                    "client_email",
                    "relationship_uuid",
                    "start_datetime",
                ]}
                filterBy={
                    tutoringPlatform
                        ? {
                              tutor_email: userInfo?.email,
                              organization: userInfo?.organization,
                          }
                        : null
                }
            />

            <ConfirmModal
                isOpen={paymentModal.isOpen}
                message={paymentModal.message}
                confirmMessage={
                    paymentModal.isConfirmation
                        ? "Confirm Payment"
                        : paymentModal.isProcessing
                          ? ""
                          : "OK"
                }
                declineMessage={paymentModal.isConfirmation ? "Cancel" : null}
                onConfirm={
                    paymentModal.isConfirmation
                        ? processPayment
                        : closePaymentModal
                }
                onClose={paymentModal.isConfirmation ? closePaymentModal : null}
                showActions={!paymentModal.isProcessing}
            />
        </>
    );
}

export default ManageSessions;
