import { useSettings } from "../../auth/SettingsContext";
import React, { useEffect, useState } from "react";
import areObjectsEqual from "../../utils/CheckDictionaryEquality";
import "../Settings.css";

import { TestInfoContainer } from "./account_components/TestInfo";
import { TutorInfoContainer } from "./account_components/TutorInfo";
import { PersonalInfoContainer } from "./account_components/PersonalInfo";

const AccountInfo = () => {
    const { settings, saveSettings, setSettings, settingsSaveAbility } =
        useSettings();
    const [saveAbility, setSaveAbility] = useState(false);
    const [originalSettings, setOriginalSettings] = useState(settings);

    const handleSaveSettings = () => {
        setOriginalSettings(settings);
        saveSettings();
    };

    useEffect(() => {
        const noChange = areObjectsEqual(settings, originalSettings);

        if (!noChange && settingsSaveAbility) {
            setSaveAbility(true);
        } else {
            setSaveAbility(false);
        }
    }, [settings, originalSettings, settingsSaveAbility]);

    return (
        <>
            <PersonalInfoContainer
                settings={settings}
                setSettings={setSettings}
                optIntoText={true}
            />
            <TestInfoContainer settings={settings} setSettings={setSettings} />
            {/* <TutorInfoContainer settings={settings} setSettings={setSettings} /> */}
            <div style={{ marginTop: "40px", marginLeft: "30px" }}>
                <button
                    className={`orange-button ${saveAbility ? "" : "disabled"}`}
                    onClick={saveAbility ? handleSaveSettings : null}
                    disabled={!saveAbility}
                    style={{ margin: "0px" }}
                >
                    Save Settings
                </button>
            </div>
        </>
    );
};

export default AccountInfo;
