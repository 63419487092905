import { createContext, useContext, useState, useEffect } from "react";
import { useStytchUser } from "@stytch/react";
import GenericAsync from "../backend/GenericAsync";
import { userEmailStore } from "../slack/SlackAlert";

const UserContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const { user: authUser } = useStytchUser();
    const [authEmail, setAuthEmail] = useState(null);
    const [authId, setAuthId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [authInfo, setAuthInfo] = useState(null);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchUserData = async () => {
            if (authUser?.emails) {
                const user_email = authUser.emails[0]?.email;
                if (user_email !== authEmail) {
                    try {
                        const response = await GenericAsync({
                            backendUrl: `${backendUrl}/api/auth/get-user`,
                            dataToSend: {
                                email: user_email,
                            },
                        });
                        setAuthInfo(response.user_info);

                        // Store email globally for error reporting
                        userEmailStore.email = user_email;
                    } catch (error) {
                        console.error("Error fetching user data:", error);
                    }
                }
            }
        };

        if (authUser) {
            // Get the primary email
            const primaryEmail = authUser.emails[0]?.email || null;
            setAuthEmail(primaryEmail);
            // Store email globally for error reporting
            userEmailStore.email = primaryEmail;

            // Set user ID
            setAuthId(authUser.user_id);

            fetchUserData();

            setIsLoading(false);
        } else {
            setAuthEmail(null);
            setAuthId(null);
            setAuthInfo(null);
            // Clear email in global store
            userEmailStore.email = null;
            setIsLoading(false);
        }
    }, [authUser]);

    // Provide both the processed data and the raw user object
    return (
        <UserContext.Provider
            value={{
                authEmail,
                authId,
                isLoading,
                authInfo,
                authUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};
